import { Field, onFieldInputValueChange } from '@formily/core'
import { Alert, Form, Modal } from 'antd'
import { saveAs } from 'file-saver'

import { PasswordRequest } from 'api/auth/interface'
import { userEditPasswordRequest } from 'api/auth/user/editPassword'

import FormItem from 'common/@formily/components/FormItem'
import Password from 'common/@formily/components/Password'
import { useCreateForm } from 'common/@formily/hooks/form'
import CrudSchemaForm from 'common/components/CrudSchemaForm'
import { CrudSchemaItem } from 'common/components/CrudSchemaForm/interface'
import useRequest from 'common/hooks/useRequest'
import { checkStrength } from 'common/lib/password'

import { ChangePasswordModalProps } from './interface'

const items: CrudSchemaItem[] = [
  {
    name: 'newPass',
    title: '新密码',
    type: 'string',
    required: true,
    'x-decorator': FormItem,
    'x-component': Password,
    'x-component-props': { checkStrength: true }
  },
  {
    name: 'newPass2',
    title: '确认密码',
    type: 'string',
    required: true,
    'x-decorator': FormItem,
    'x-component': Password
  }
]

const newPassEffect = () => {
  onFieldInputValueChange('*(newPass,newPass2)', (_, form) => {
    const { newPass, newPass2 } = form.values

    const newPassField = form.query('newPass').take() as Field
    newPassField.selfErrors = checkStrength(newPass)
      ? []
      : ['密码必须为 8~20 位字母、数字及特殊符号（~!@#$%^&*）的组合']

    if (!newPass2) {
      return
    }

    const newPass2Field = form.query('newPass2').take() as Field
    newPass2Field.selfErrors =
      newPass !== newPass2 ? ['两次密码输入不一致'] : []
  })
}

const downloadPassword = (account?: string, password?: string) => {
  const blob = new Blob([`账号：${account}\n密码：${password}`])
  const url = URL.createObjectURL(blob)
  saveAs(url, `新密码-${account}.txt`)
}

const ChangePasswordModal = ({
  userId,
  account,
  download = true,
  closable = true,
  open,
  title = '修改密码',
  onCancel,
  onSuccess
}: ChangePasswordModalProps) => {
  const form = useCreateForm<PasswordRequest>({
    effects: newPassEffect
  })

  const { error, loading, run } = useRequest({
    api: userEditPasswordRequest,
    manual: true
  })

  const onSubmit = async () => {
    form.submit(async values => {
      const formData = { ...values, id: userId }

      await run(formData)

      form.reset(undefined, {
        validate: false
      })

      if (download) {
        try {
          downloadPassword(account, formData.newPass)
        } catch (e) {
          console.error(e)
        }
      }

      onSuccess?.(formData)
    })
  }

  return userId ? (
    <Modal
      cancelButtonProps={
        closable
          ? undefined
          : {
              hidden: true
            }
      }
      closable={closable}
      okButtonProps={{
        loading
      }}
      open={open}
      title={title}
      onCancel={onCancel}
      onOk={onSubmit}
    >
      <CrudSchemaForm
        form={form}
        items={items}
        labelWidth={100}
        name='changePassword'
        wrapperWidth={200}
      >
        {error && (
          <Form.Item>
            <Alert closable={false} message={error.message} type='error' />
          </Form.Item>
        )}
      </CrudSchemaForm>
    </Modal>
  ) : null
}

export default ChangePasswordModal
