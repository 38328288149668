import { useContext } from 'react'

import SizeContext, { SizeType } from 'antd/es/config-provider/SizeContext'

const transformSize = (size?: SizeType) => {
  return size === 'middle' ? 'default' : size
}

export const useSize = (size?: SizeType, transformed?: boolean) => {
  const contextSize = useContext(SizeContext)

  return size ?? contextSize
}

export const useTransformSize = (size?: SizeType) => {
  return transformSize(useSize(size))
}
