import { OptionItem } from 'common/router/interface'

/**
 * 页面上的操作
 */
export const DEPT_ADD_FINANCE_BTN = 'deptAddFinanceBtn'

export const DEPT_ADD_MANAGER_BTN = 'deptAddManagerBtn'

export const DEPT_ADD_DEPUTY_BTN = 'deptAddDeputyBtn'

export const DEPT_ADD_PERSON_BTN = 'deptAddPersonBtn'

export const DEPT_ADD_PERSON_OTHER_BTN = 'deptAddPersonOtherBtn'

export const DEPT_ADD_FINANCE_OPTION: OptionItem = {
  key: DEPT_ADD_FINANCE_BTN,
  name: '配置财务人员'
}
export const DEPT_ADD_MANAGER_OPTION: OptionItem = {
  key: DEPT_ADD_MANAGER_BTN,
  name: '配置部门经理'
}

export const DEPT_ADD_DEPUTY_OPTION: OptionItem = {
  key: DEPT_ADD_DEPUTY_BTN,
  name: '配置分管领导'
}

export const DEPT_ADD_PERSON_OPTION: OptionItem = {
  key: DEPT_ADD_PERSON_BTN,
  name: '配置数据可见人员'
}

export const DEPT_ADD_PERSON_OTHER_OPTION: OptionItem = {
  key: DEPT_ADD_PERSON_OTHER_BTN,
  name: '配置部门附属人员'
}
