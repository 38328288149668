/**
 * 「新增按钮」权限标识
 */
export const CREATE_BTN = 'createBtn'

/**
 * 「编辑按钮」权限标识
 */
export const UPDATE_BTN = 'updateBtn'

/**
 * 「查看按钮」权限标识
 */
export const READ_BTN = 'readBtn'

/**
 * 「删除按钮」权限标识
 */
export const DELETE_BTN = 'deleteBtn'

/**
 * 「审核按钮」权限标识
 */
export const AUDIT_BTN = 'auditBtn'

/**
 * 「反审核按钮」权限标识
 */
export const AUDIT_CANCEL_BTN = 'auditCancelBtn'

/**
 * 「作废按钮」权限标识
 */
export const INVALID_BTN = 'invalidBtn'

/**
 * 「批量审批按钮」权限标识
 */
export const WORKFLOW_BATCH_BTN = 'workflowBatchBtn'

/**
 * 「全部审批按钮」权限标识
 */
export const WORKFLOW_ALL_BTN = 'workflowAllBtn'

/**
 * 「审批记录按钮」权限标识
 */
export const WORKFLOW_RECORD_BTN = 'workflowRecordBtn'
