import { RouteItem } from 'common/router/interface'

import Login from 'hsu/pages/Login/index'

const login: RouteItem = {
  key: 'login',
  name: '登录授权',
  path: '/login',
  layout: 'UserLayout',
  hideInMenu: true,
  authorize: false,
  lazy: false,
  component: Login
}

export default login
