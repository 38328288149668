/**
 * @desc 根据id查询
 */
import { AcceptBillEntity } from 'api/hsu/interface'
import fetcher from 'common/lib/fetcher'
import { MyAxiosRequestConfig } from 'common/interface/crud'

export type AcceptBillGetByIdParams = Partial<{
  /** id */
  id: string
}>

export type AcceptBillGetByIdResponse = AcceptBillEntity

export const acceptBillGetByIdMethod = 'GET'

export const acceptBillGetByIdUrl = '/hsu/v1/acceptBill/byId/{id}'

export function acceptBillGetByIdRequest(
  params?: AcceptBillGetByIdParams,
  options?: MyAxiosRequestConfig
) {
  return fetcher.request<AcceptBillGetByIdResponse>({
    url: acceptBillGetByIdUrl,
    method: acceptBillGetByIdMethod,
    params,
    ...options
  })
}

acceptBillGetByIdRequest.method = acceptBillGetByIdMethod
acceptBillGetByIdRequest.url = acceptBillGetByIdUrl
