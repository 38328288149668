import classNames from 'classnames'

import { useSize } from 'common/hooks/useSize'
import { BaseProps } from 'common/interface/component'
import './index.less'

export type PageContainerProps = BaseProps & {
  children?: React.ReactNode
}

const PageContainer = (props: PageContainerProps) => {
  const { className, style, children } = props

  const size = useSize()

  const prefixCls = 'page-container'
  const rootClassName = classNames(
    prefixCls,
    {
      [`${prefixCls}-sm`]: size === 'small'
    },
    className
  )

  return (
    <div className={rootClassName} style={style}>
      {children}
    </div>
  )
}

export default PageContainer
