/**
 * @desc 根据id查询
 */
import { CargoEntryExitEntity } from 'api/hsu/interface'
import fetcher from 'common/lib/fetcher'
import { MyAxiosRequestConfig } from 'common/interface/crud'

export type CargoEntryExitGetByIdParams = Partial<{
  /** id */
  id: string
}>

export type CargoEntryExitGetByIdResponse = CargoEntryExitEntity

export const cargoEntryExitGetByIdMethod = 'GET'

export const cargoEntryExitGetByIdUrl = '/hsu/v1/cargoEntryExit/byId/{id}'

export function cargoEntryExitGetByIdRequest(
  params?: CargoEntryExitGetByIdParams,
  options?: MyAxiosRequestConfig
) {
  return fetcher.request<CargoEntryExitGetByIdResponse>({
    url: cargoEntryExitGetByIdUrl,
    method: cargoEntryExitGetByIdMethod,
    params,
    ...options
  })
}

cargoEntryExitGetByIdRequest.method = cargoEntryExitGetByIdMethod
cargoEntryExitGetByIdRequest.url = cargoEntryExitGetByIdUrl
