import { connect, mapReadPretty } from '@formily/react'
import { Input as AntdInput, InputProps as AntdInputProps } from 'antd'

import PreviewText from '../../shared/PreviewText'

import useName from './hooks/useName'
import { InputProps } from './interface'

const Input = (props: InputProps) => {
  const { value, onChange, ...inputProps } = props

  const { ref, name } = useName()

  const onInputChange: AntdInputProps['onChange'] = e => {
    onChange?.(e.target.value)
  }

  return (
    <AntdInput
      ref={ref}
      allowClear
      autoComplete='on'
      name={name}
      value={value}
      onChange={onInputChange}
      {...inputProps}
    />
  )
}

export default connect(Input, mapReadPretty(PreviewText))
