import { List, Spin, Typography } from 'antd'
import moment from 'moment'

import { MessageEntity } from 'api/hsu/interface'

import InfiniteScroll from 'common/components/InfiniteScroll'
import ScrollContainer from 'common/components/ScrollContainer'
import { useTransformSize } from 'common/hooks/useSize'
import { Noop } from 'common/interface/base'

import LinkButton from './LinkButton'

export type TodoListProps = {
  loading?: boolean
  total?: number
  data?: MessageEntity[]
  loadMore: Noop
}

const TodoList = (props: TodoListProps) => {
  const { loading, total, data, loadMore } = props

  const size = useTransformSize()

  const hasMore = !loading && data?.length !== total

  const indicator = total ? (
    <div style={{ padding: '24px', textAlign: 'center' }}>
      <Typography.Text type='secondary'>{`${data?.length}/${total}`}</Typography.Text>
    </div>
  ) : null

  return (
    <Spin spinning={loading}>
      <ScrollContainer height={420}>
        <InfiniteScroll
          hasMore={hasMore}
          initialLoad={false}
          loadMore={loadMore}
          pageStart={0}
          useWindow={false}
        >
          <List
            dataSource={data}
            loadMore={indicator}
            renderItem={item => {
              return (
                <List.Item
                  key={item.id}
                  actions={[<LinkButton item={item} />]}
                  style={{ paddingRight: '0px' }}
                >
                  <List.Item.Meta
                    description={
                      <Typography.Text title={item.time} type='secondary'>
                        {moment(item.time).fromNow()}
                        <span>&nbsp;/&nbsp;</span>
                        <span>{item.time}</span>
                      </Typography.Text>
                    }
                    title={item.content}
                  />
                </List.Item>
              )
            }}
            size={size}
          />
        </InfiniteScroll>
      </ScrollContainer>
    </Spin>
  )
}

export default TodoList
