import { createContext, useContext } from 'react'

const PlaceholderContext = createContext<string>('')

export const PlaceholderProvider = PlaceholderContext.Provider

export const usePlaceholder = (value?: any) => {
  const placeholder = useContext(PlaceholderContext)

  return value ?? placeholder
}
