import { FC } from 'react'

import { PROJECT_TITLE } from 'common/lib/constant'
import './index.less'

const UserLayout: FC = ({ children }) => {
  return (
    <div className='user-layout'>
      <div className='user-layout__content'>
        <div className='user-layout__top'>
          <div className='user-layout__header'>
            <img alt='logo' className='user-layout__logo' src='/logo.png' />
            <span className='user-layout__title'>{PROJECT_TITLE}</span>
          </div>
          <div className='user-layout__desc'>简洁、易用</div>
        </div>
        {children}
      </div>
    </div>
  )
}

export default UserLayout
