import { acceptBillGetByIdRequest } from 'api/hsu/acceptBill/getById'
import { appraisalBillGetByIdRequest } from 'api/hsu/appraisalBill/getById'
import { billGetByIdRequest } from 'api/hsu/bill/getById'
import { bmBargainGetByIdRequest } from 'api/hsu/bmBargain/getById'
import { cargoAdjustGetByIdRequest } from 'api/hsu/cargoAdjust/getById'
import { cargoEntryExitGetByIdRequest } from 'api/hsu/cargoEntryExit/getById'
import { customerProfileGetByIdRequest } from 'api/hsu/customerProfile/getById'
import { estimatedReceiptBillGetByIdRequest } from 'api/hsu/estimatedReceiptBill/getById'
import { paymentRequestFormGetByIdRequest } from 'api/hsu/paymentRequestForm/getById'
import { procurementContractGetByIdRequest } from 'api/hsu/procurementContract/getById'
import { puBargainGetByIdRequest } from 'api/hsu/puBargain/getById'
import { puPriceCompareGetByIdRequest } from 'api/hsu/puPriceCompare/getById'
import { saleBillGetByIdRequest } from 'api/hsu/saleBill/getById'
import { saleContractGetByIdRequest } from 'api/hsu/saleContract/getById'

import { ConfigItem } from './Todo/interface'

export const getGreeting = () => {
  const hours = new Date().getHours()
  if (hours > 5 && hours < 12) {
    return '上午好'
  }
  if (hours >= 12 && hours <= 13) {
    return '中午好'
  }
  if (hours > 13 && hours < 19) {
    return '下午好'
  }
  return '晚上好'
}

export const ConfigMap: Record<string | number, ConfigItem | undefined> = {
  // 企业档案
  customer: {
    basePath: '/customer/profile',
    api: customerProfileGetByIdRequest
  },
  // 议价单
  bargain: {
    basePath: '/purchase/bargain',
    api: bmBargainGetByIdRequest
  },
  // 议价审批单
  pubargain: {
    basePath: '/purchase/bargainApprove',
    api: puBargainGetByIdRequest
  },
  // 比价审批单
  pupricecompare: {
    basePath: '/purchase/enquiryApprove',
    api: puPriceCompareGetByIdRequest
  },
  // 采购合同
  purchasecontract: {
    basePath: '/contract/purchase',
    api: procurementContractGetByIdRequest
  },
  // 销售合同
  salecontract: {
    basePath: '/contract/sale',
    api: saleContractGetByIdRequest
  },
  // 验收单
  acceptbill: {
    basePath: '/inventory/acceptance',
    api: acceptBillGetByIdRequest
  },
  // 估收单
  estimatebill: {
    basePath: '/inventory/estimatedreceipt',
    api: estimatedReceiptBillGetByIdRequest
  },
  // 冲估单
  balanceestimate: {
    basePath: '/inventory/appraisal',
    api: appraisalBillGetByIdRequest
  },
  // 出售单
  icsalebill: {
    basePath: '/inventory/salebill',
    api: saleBillGetByIdRequest
  },
  // 移库入库
  movein: {
    basePath: '/inventory/cargoOfMoving',
    api: cargoEntryExitGetByIdRequest
  },
  // 调整单
  adjust: {
    basePath: '/inventory/adjust',
    api: cargoAdjustGetByIdRequest
  },
  // 付款单（华塑）
  paybill: {
    basePath: '/finance/payment',
    api: paymentRequestFormGetByIdRequest
  },
  // 其他合同
  Ge0: {
    basePath: '/contract/other',
    api: billGetByIdRequest
  },
  // 运输合同
  Ge1: {
    basePath: '/contract/transport',
    api: billGetByIdRequest
  },
  // 服务合同
  Ge2: {
    basePath: '/contract/serve',
    api: billGetByIdRequest
  },
  // 其他审批
  Ge3: {
    basePath: '/approve/other',
    api: billGetByIdRequest
  }
}
