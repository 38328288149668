/**
 * @desc 当前人员是否有审批
 */

import fetcher from 'common/lib/fetcher'
import { MyAxiosRequestConfig } from 'common/interface/crud'

export type WorkFlowCheckApproveParams = Partial<{
  /** workFlowId */
  workFlowId: number
}>

export type WorkFlowCheckApproveResponse = string

export const workFlowCheckApproveMethod = 'GET'

export const workFlowCheckApproveUrl =
  '/hsu/v1/workflow/checkApprove/{workFlowId}'

export function workFlowCheckApproveRequest(
  params?: WorkFlowCheckApproveParams,
  options?: MyAxiosRequestConfig
) {
  return fetcher.request<WorkFlowCheckApproveResponse>({
    url: workFlowCheckApproveUrl,
    method: workFlowCheckApproveMethod,
    params,
    ...options
  })
}

workFlowCheckApproveRequest.method = workFlowCheckApproveMethod
workFlowCheckApproveRequest.url = workFlowCheckApproveUrl
