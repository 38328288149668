import {
  CREATE_BTN,
  DELETE_BTN,
  UPDATE_BTN,
  READ_BTN,
  INVALID_BTN,
  AUDIT_BTN,
  AUDIT_CANCEL_BTN,
  WORKFLOW_BATCH_BTN,
  WORKFLOW_ALL_BTN,
  WORKFLOW_RECORD_BTN
} from 'common/lib/identify'

import { OptionItem } from './interface'

export const CREATE_OPTION: OptionItem = {
  key: CREATE_BTN,
  name: '新增'
}

export const UPDATE_OPTION: OptionItem = {
  key: UPDATE_BTN,
  name: '编辑'
}

export const DELETE_OPTION: OptionItem = {
  key: DELETE_BTN,
  name: '删除'
}

export const READ_OPTION: OptionItem = {
  key: READ_BTN,
  name: '查看'
}

export const AUDIT_OPTION: OptionItem = {
  key: AUDIT_BTN,
  name: '审核'
}

export const AUDIT_CANCEL_OPTION: OptionItem = {
  key: AUDIT_CANCEL_BTN,
  name: '反审核'
}

export const INVALID_OPTION: OptionItem = {
  key: INVALID_BTN,
  name: '作废'
}

export const WORKFLOW_BATCH_OPTION: OptionItem = {
  key: WORKFLOW_BATCH_BTN,
  name: '批量审批'
}

export const WORKFLOW_ALL_OPTION: OptionItem = {
  key: WORKFLOW_ALL_BTN,
  name: '全部审批'
}

export const WORKFLOW_RECORD_OPTION: OptionItem = {
  key: WORKFLOW_RECORD_BTN,
  name: '全部审批'
}
