/**
 *  密码必须为 8~20 位字母、数字及特殊符号（~!@#$%^&*）的组合
 */
export const checkStrength = (value: string) => {
  return (
    value.length >= 8 &&
    value.length <= 20 &&
    /\d+/.test(value) &&
    /[a-zA-Z]+/.test(value) &&
    /[~!@#$%^&*]+/.test(value)
  )
}
