import { useRef } from 'react'

import { isEqual } from './util'

const useParamsTracer = (params?: any) => {
  const ref = useRef<any>()

  const changed = !isEqual(ref.current, params)

  // console.group()
  // console.log(changed, ref.current, params)
  // console.groupEnd()

  if (changed) {
    ref.current = params
  }

  return changed ? Math.random() : 0
}

export default useParamsTracer
