import { useRef } from 'react'

import { debounce, DebounceSettings } from 'lodash-es'

import { Noop } from 'common/interface/base'

import useCreation from './useCreation'
import useUnmount from './useUnmount'

type DebounceOptions = DebounceSettings & {
  wait?: number
}
const useDebounceFn = <T extends Noop>(fn: T, options?: DebounceOptions) => {
  const fnRef = useRef<T>(fn)

  fnRef.current = fn

  const wait = options?.wait ?? 1000

  const debounced = useCreation(
    () =>
      debounce(
        ((...args: any[]) => {
          return fnRef.current(...args)
        }) as T,
        wait,
        options
      ),
    []
  )

  useUnmount(() => {
    debounced.cancel()
  })

  return debounced
}

export default useDebounceFn
