import { useMemo } from 'react'

import { Badge as AntdBadge } from 'antd'

import { useSize } from 'common/hooks/useSize'

export type TodoBadgeProps = {
  total?: number
}

const TodoBadge = ({ total }: TodoBadgeProps) => {
  const size = useSize()

  const badgeSize = size === 'small' ? 'small' : 'default'

  const offset: [number, number] = useMemo(
    () => (badgeSize === 'small' ? [15, 0] : [20, 0]),
    [badgeSize]
  )

  return (
    <AntdBadge
      count={total}
      offset={offset}
      overflowCount={99}
      showZero={false}
      size={badgeSize}
      title={`您共有 ${total} 条待办`}
    >
      待办事项
    </AntdBadge>
  )
}

export default TodoBadge
