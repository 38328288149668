/**
 * @desc undefined
 */

import fetcher from 'common/lib/fetcher'
import { MyAxiosRequestConfig } from 'common/interface/crud'

export type KaptchaCreateVerifyCodeParams = Partial<{}>

export type KaptchaCreateVerifyCodeResponse = any

export const kaptchaCreateVerifyCodeMethod = 'GET'

export const kaptchaCreateVerifyCodeUrl = '/oauth2/verifycode'

export function kaptchaCreateVerifyCodeRequest(
  params?: KaptchaCreateVerifyCodeParams,
  options?: MyAxiosRequestConfig
) {
  return fetcher.request<KaptchaCreateVerifyCodeResponse>({
    url: kaptchaCreateVerifyCodeUrl,
    method: kaptchaCreateVerifyCodeMethod,
    params,
    ...options
  })
}

kaptchaCreateVerifyCodeRequest.method = kaptchaCreateVerifyCodeMethod
kaptchaCreateVerifyCodeRequest.url = kaptchaCreateVerifyCodeUrl
