import { Component, createElement } from 'react'

import { noop } from 'lodash-es'

import { InfiniteScrollProps, ScrollElType } from './interface'

export default class InfiniteScroll extends Component<InfiniteScrollProps> {
  static defaultProps: InfiniteScrollProps = {
    pageStart: 0,
    threshold: 100,
    hasMore: false,
    initialLoad: true,
    useWindow: true,
    useCapture: false
  }

  private options: AddEventListenerOptions | undefined
  private scrollComponent: HTMLElement | null = null

  private pageLoaded = 0

  componentDidMount() {
    this.pageLoaded = this.props.pageStart || 0
    this.options = this.eventListenerOptions()
    this.attachScrollListener()
  }

  componentDidUpdate(prevProps: InfiniteScrollProps) {
    if (prevProps.hasMore !== this.props.hasMore) {
      this.attachScrollListener()
    }
  }

  componentWillUnmount() {
    this.detachScrollListener()
  }
  // 检测浏览器是否支持Passive
  isPassiveSupported = () => {
    let passive = false
    // 当属性passive的值为true的时候，代表该监听器内部不会调用preventDefault函数来阻止默认滑动行为
    const testOptions = {
      get passive() {
        passive = true
        return passive
      }
    }

    try {
      document.addEventListener('click', noop, testOptions)
      document.removeEventListener('click', noop)
    } catch (e) {
      // ignore
    }
    return passive
  }
  // 监听选项
  eventListenerOptions = () => {
    return this.isPassiveSupported()
      ? {
          useCapture: this.props.useCapture,
          passive: true
        }
      : {
          passive: false
        }
  }
  // 撤销监听
  detachScrollListener = () => {
    let scrollEl: ScrollElType = window
    if (this.props.useWindow === false) {
      scrollEl = this.getParentElement(this.scrollComponent)
    }

    scrollEl?.removeEventListener(
      'scroll',
      this.scrollListener,
      this.options ? this.options : this.props.useCapture
    )
    scrollEl?.removeEventListener(
      'resize',
      this.scrollListener,
      this.options ? this.options : this.props.useCapture
    )
  }

  getParentElement = (el: HTMLElement | null) => {
    const scrollParent = this.props.getScrollParent?.()

    return scrollParent || el?.parentElement
  }
  // 添加监听
  attachScrollListener = () => {
    const parentElement = this.getParentElement(this.scrollComponent)
    if (!this.props.hasMore || !parentElement) {
      return
    }

    let scrollEl: ScrollElType = window
    if (this.props.useWindow === false) {
      scrollEl = parentElement
    }

    scrollEl?.addEventListener(
      'scroll',
      this.scrollListener,
      this.options ? this.options : this.props.useCapture
    )
    scrollEl?.addEventListener(
      'resize',
      this.scrollListener,
      this.options ? this.options : this.props.useCapture
    )

    if (this.props.initialLoad) {
      this.scrollListener()
    }
  }

  scrollListener = () => {
    const el = this.scrollComponent
    const scrollEl = window
    const parentEl = this.getParentElement(el)

    let offset = 0

    if (this.props.useWindow) {
      const doc =
        document.documentElement || document.body.parentNode || document.body
      const scrollTop =
        scrollEl.pageYOffset !== undefined
          ? scrollEl.pageYOffset
          : doc.scrollTop
      offset = this.calculateOffset(el, scrollTop)
    } else {
      if (el && parentEl) {
        offset = el.scrollHeight - parentEl.scrollTop - parentEl.clientHeight
      }
    }
    // Here we make sure the element is visible as well as checking the offset
    if (
      offset < Number(this.props.threshold) &&
      el &&
      el.offsetParent &&
      parentEl
    ) {
      this.detachScrollListener()
      // Call loadMore after detachScrollListener to allow for non-async loadMore functions
      if (this.props.loadMore) {
        this.pageLoaded++
        this.props.loadMore(this.pageLoaded)
      }
    }
  }

  calculateOffset = (el: HTMLElement | null, scrollTop: number) => {
    if (!el) {
      return 0
    }

    return (
      this.calculateTopPosition(el) +
      (el.offsetHeight - scrollTop - window.innerHeight)
    )
  }

  calculateTopPosition = (el: HTMLElement | null): number => {
    if (!el) {
      return 0
    }
    return (
      el.offsetTop + this.calculateTopPosition(el.offsetParent as HTMLElement)
    )
  }

  render() {
    const { hasMore, loader, children } = this.props

    const childrenArray: React.ReactNode[] = []
    if (children) {
      childrenArray.push(children)
    }
    if (hasMore && loader) {
      childrenArray.push(loader)
    }
    return createElement(
      'div',
      {
        ref: (node: HTMLElement) => {
          this.scrollComponent = node
          if (this.props.ref) {
            this.props.ref.current = node
          }
        }
      },
      childrenArray
    )
  }
}
