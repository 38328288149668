import hash from 'object-hash'

import { AxiosRequest } from 'common/interface/crud'

import CacheManager from './cache'
import {
  CacheKey,
  FetcherOptions,
  FetchState,
  OnErrorHandle,
  OnSuccessHandle,
  Subscribe
} from './interface'
import { createDelay } from './util'

const cacheManager = new CacheManager({ defaultCacheTime: 5 * 60 * 1000 })

class Fetcher<T, P> {
  private api: AxiosRequest<T, P>

  private cacheKey?: CacheKey
  private defaultCache?: boolean

  private subscribe: Subscribe<T, P>

  private onError?: OnErrorHandle
  private onSuccess?: OnSuccessHandle<T>

  private requestIndex = 0

  private state: FetchState<T, P> = {
    data: undefined,
    error: undefined,
    loading: false,
    requested: false,
    params: {},
    mutate: this.mutate.bind(this),
    refresh: this.refresh.bind(this),
    run: this.run.bind(this)
  }

  constructor(options: FetcherOptions<T, P>) {
    const {
      api,
      cacheKey,
      cacheTime,
      defaultCache,
      subscribe,
      onError,
      onSuccess
    } = options

    this.api = api
    this.cacheKey = cacheKey
    this.defaultCache = defaultCache
    this.subscribe = subscribe
    this.onError = onError
    this.onSuccess = onSuccess

    if (this.cacheKey) {
      cacheManager.init(this.cacheKey, { cacheTime })
    }
  }

  private createDefaultCacheKey(url: string, params: any) {
    const hashCode = hash(params, {
      algorithm: 'md5',
      respectFunctionProperties: false,
      excludeKeys(key) {
        return key.startsWith('_')
      }
    })

    return url + '_' + hashCode
  }

  private getCacheKey(params: any) {
    if (this.cacheKey) {
      return this.cacheKey
    }

    if (this.defaultCache && this.api.url && this.api.method === 'GET') {
      return this.createDefaultCacheKey(this.api.url, params)
    }

    return undefined
  }
  private getCache(params: any) {
    return cacheManager.get(this.getCacheKey(params))
  }

  private setCache(value: any, params: any) {
    const cacheKey = this.getCacheKey(params)

    if (cacheKey) {
      cacheManager.set(cacheKey, value)
    }
  }

  private setState(state: Partial<FetchState<T, P>>) {
    this.state = {
      ...this.state,
      ...state,
      requested: this.isRequested()
    }
    this.subscribe?.(this.state)
  }

  getState() {
    return this.state
  }

  isRequested() {
    return this.requestIndex > 0
  }

  private async request(params?: any, config?: any) {
    const cacheValue = this.getCache(params)

    if (cacheValue) {
      // console.log('============= 请求触发缓存 ===============')
      // console.log(this.api.url, params)
      // console.log('========================================')
      return cacheValue as T
    }

    const data = await this.api(params, config)

    return data
  }

  async run(params?: any, config?: any) {
    const delay = createDelay()

    this.requestIndex++

    this.setState({
      params,
      loading: true
    })

    try {
      const data = await this.request(params, config)

      this.setState({
        data,
        error: undefined
      })

      this.setCache(data, params)
      this.onSuccess?.(data)

      return data
    } catch (error: any) {
      this.setState({ error })
      this.onError?.(error)
      throw error
    } finally {
      delay(() => {
        this.setState({ loading: false })
      }, 200)
    }
  }

  refresh() {
    return this.run(this.state.params)
  }

  mutate(data?: T) {
    this.setState({ data })
  }
}

export default Fetcher
