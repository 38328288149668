import { Button, ButtonProps } from 'antd'

import useThrottleFn from 'common/hooks/useThrottleFn'

/**
 * 带节流功能的 button，防止多次点击
 */
const ThrottleButton = (props: ButtonProps) => {
  const { onClick, ...buttonProps } = props

  const onButtonClick: ButtonProps['onClick'] = useThrottleFn(
    e => {
      onClick?.(e)
    },
    {
      // 1s 内只允许触发一次 click 事件
      maxWait: 1000
    }
  )

  return <Button {...buttonProps} onClick={onButtonClick} />
}

export default ThrottleButton
