import { createContext, useContext } from 'react'

import { SiderProps, SiderTheme } from 'antd/es/layout/Sider'

import { ApproveRecordEntity } from 'common/interface/project'

export type ProjectConfigContextState = {
  /**
   * 是否展示页头信息
   */
  showHeader?: boolean

  /**
   * 主题
   */
  theme?: SiderTheme

  /**
   * 菜单栏默认是否折叠
   */
  defaultCollapsed?: SiderProps['defaultCollapsed']

  /**
   * 分页大小
   */
  pageSize?: number

  apis: {
    /**
     * 收藏功能的接口
     */
    collect: {
      create: (...args: any[]) => Promise<any>
      read: (...args: any[]) => Promise<any[]>
      delete: (...args: any[]) => Promise<any>
    }
    /**
     * 流程模块的接口
     * △：workFlowId 大小写不要变，需要跟 services 模块中的接口保持一致
     */
    workflow: {
      checkApprovePermission: (params: {
        workFlowId: number
      }) => Promise<string>
      getApproveRecords: (params: {
        workFlowId: string
      }) => Promise<ApproveRecordEntity[]>
    }
  }
}

const ProjectConfigContext = createContext<ProjectConfigContextState>({
  apis: {
    collect: {
      create: () => Promise.resolve(),
      read: () => Promise.resolve([]),
      delete: () => Promise.resolve()
    },
    workflow: {
      checkApprovePermission: () => Promise.resolve('0'),
      getApproveRecords: () => Promise.resolve([])
    }
  }
})

export const ProjectConfigProvider = ProjectConfigContext.Provider

export const useProjectConfig = () => {
  return useContext(ProjectConfigContext)
}
