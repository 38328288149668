import { registerValidateRules } from '@formily/core'
import {
  IValidatorRules,
  registerValidateLocale,
  setValidateLanguage
} from '@formily/validator'

import { isEmpty } from 'common/lib/util'

export const formilySetup = () => {
  setValidateLanguage('zh-CN')
  registerValidateLocale({
    'zh-CN': {
      required: '该字段不能为空'
    }
  })
  registerValidateRules({
    required(value: any, rule: IValidatorRules<any>) {
      if (rule.required === false) return ''

      let flag = isEmpty(value)

      if (!flag && typeof value === 'object') {
        const keys = Object.keys(value)
        flag = keys.every(key => isEmpty(value[key]))
      }

      return flag ? rule.message || '' : ''
    }
  })
}
