import { BankOutlined } from '@ant-design/icons'

import { RouteItem } from 'common/router/interface'
import {
  CREATE_OPTION,
  DELETE_OPTION,
  UPDATE_OPTION,
  READ_OPTION
} from 'common/router/option'

import { ACCEPTANCE_ADJUST_OPTION } from 'hsu/pages/Inventory/Acceptance/option'
import { SALE_BILL_ADJUST_BTN_OPTION } from 'hsu/pages/Inventory/SaleBill/option'

const inventory: RouteItem = {
  key: 'inventory',
  name: '仓储管理',
  path: '/inventory',
  redirect: '/inventory',
  layout: 'PageLayout',
  icon: <BankOutlined />,
  children: [
    {
      key: 'inventoryAcceptance',
      name: '验收单',
      path: '/inventory/acceptance',
      redirect: '/inventory/acceptance/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'inventoryAcceptanceList',
          name: '验收单列表',
          path: '/inventory/acceptance/list',
          options: [
            CREATE_OPTION,
            DELETE_OPTION,
            UPDATE_OPTION,
            READ_OPTION,
            ACCEPTANCE_ADJUST_OPTION
          ],
          component: () => import('hsu/pages/Inventory/Acceptance/List')
        },
        {
          key: 'inventoryAcceptanceCreate',
          name: '新增验收单',
          path: '/inventory/acceptance/create',
          component: () => import('hsu/pages/Inventory/Acceptance/Create')
        },
        {
          key: 'inventoryAcceptanceEdit',
          name: '编辑验收单',
          path: '/inventory/acceptance/edit',
          component: () => import('hsu/pages/Inventory/Acceptance/Edit')
        },
        {
          key: 'inventoryAcceptanceDetail',
          name: '查看验收单',
          path: '/inventory/acceptance/detail',
          component: () => import('hsu/pages/Inventory/Acceptance/Detail')
        },
        {
          key: 'inventoryAcceptanceAdjust',
          name: '验收单调整',
          path: '/inventory/acceptance/adjust',
          component: () => import('hsu/pages/Inventory/Acceptance/AdjustForm')
        }
      ]
    },
    {
      key: 'inventoryEstimatedreceipt',
      name: '估收单',
      path: '/inventory/estimatedreceipt',
      redirect: '/inventory/estimatedreceipt/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'inventoryEstimatedreceiptList',
          name: '估收单列表',
          path: '/inventory/estimatedreceipt/list',
          options: [CREATE_OPTION, DELETE_OPTION, UPDATE_OPTION, READ_OPTION],
          component: () => import('hsu/pages/Inventory/EstimatedReceipt/List')
        },
        {
          key: 'inventoryEstimatedreceiptCreate',
          name: '新增估收单',
          path: '/inventory/estimatedreceipt/create',
          component: () => import('hsu/pages/Inventory/EstimatedReceipt/Create')
        },
        {
          key: 'inventoryEstimatedreceiptEdit',
          name: '编辑估收单',
          path: '/inventory/estimatedreceipt/edit',
          component: () => import('hsu/pages/Inventory/EstimatedReceipt/Edit')
        },
        {
          key: 'inventoryEstimatedreceiptDetail',
          name: '查看估收单',
          path: '/inventory/estimatedreceipt/detail',
          component: () => import('hsu/pages/Inventory/EstimatedReceipt/Detail')
        }
      ]
    },
    {
      key: 'inventoryApprasial',
      name: '冲估单',
      path: '/inventory/appraisal',
      redirect: '/inventory/appraisal/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'inventoryApprasialList',
          name: '冲估单列表',
          path: '/inventory/appraisal/list',
          options: [CREATE_OPTION, DELETE_OPTION, UPDATE_OPTION, READ_OPTION],
          component: () => import('hsu/pages/Inventory/Appraisal/List')
        },
        {
          key: 'inventoryApprasialCreate',
          name: '新增冲估单',
          path: '/inventory/appraisal/create',
          component: () => import('hsu/pages/Inventory/Appraisal/Create')
        },
        {
          key: 'inventoryApprasialEdit',
          name: '编辑冲估单',
          path: '/inventory/appraisal/edit',
          component: () => import('hsu/pages/Inventory/Appraisal/Edit')
        },
        {
          key: 'inventoryApprasialDetail',
          name: '查看冲估单',
          path: '/inventory/appraisal/detail',
          component: () => import('hsu/pages/Inventory/Appraisal/Detail')
        }
      ]
    },
    {
      key: 'inventorySaleBill',
      name: '出售单',
      path: '/inventory/salebill',
      redirect: '/inventory/salebill/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'inventorySaleBillList',
          name: '出售单列表',
          path: '/inventory/salebill/list',
          options: [
            CREATE_OPTION,
            DELETE_OPTION,
            UPDATE_OPTION,
            READ_OPTION,
            SALE_BILL_ADJUST_BTN_OPTION
          ],
          component: () => import('hsu/pages/Inventory/SaleBill/List')
        },
        {
          key: 'inventorySaleBillCreate',
          name: '新增出售单',
          path: '/inventory/salebill/create',
          component: () => import('hsu/pages/Inventory/SaleBill/Create')
        },
        {
          key: 'inventorySaleBillEdit',
          name: '编辑出售单',
          path: '/inventory/salebill/edit',
          component: () => import('hsu/pages/Inventory/SaleBill/Edit')
        },
        {
          key: 'inventorySaleBillDetail',
          name: '查看出售单',
          path: '/inventory/salebill/detail',
          component: () => import('hsu/pages/Inventory/SaleBill/Detail')
        },
        {
          key: 'inventorySaleBillAdjust',
          name: '出售单调整',
          path: '/inventory/salebill/adjust',
          component: () => import('hsu/pages/Inventory/SaleBill/AdjustForm')
        }
      ]
    },
    {
      key: 'inventoryAdjust',
      name: '调整单',
      path: '/inventory/adjust',
      redirect: '/inventory/adjust/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'inventoryAdjustList',
          name: '调整单列表',
          path: '/inventory/adjust/list',
          options: [CREATE_OPTION, DELETE_OPTION, UPDATE_OPTION, READ_OPTION],
          component: () => import('hsu/pages/Inventory/Adjust/List')
        },
        {
          key: 'inventoryAdjustCreate',
          name: '新增调整单',
          path: '/inventory/adjust/create',
          component: () => import('hsu/pages/Inventory/Adjust/Create')
        },
        {
          key: 'inventoryAdjustEdit',
          name: '编辑调整单',
          path: '/inventory/adjust/edit',
          component: () => import('hsu/pages/Inventory/Adjust/Edit')
        },
        {
          key: 'inventoryAdjustDetail',
          name: '查看调整单',
          path: '/inventory/adjust/detail',
          component: () => import('hsu/pages/Inventory/Adjust/Detail')
        }
      ]
    },
    {
      key: 'inventoryCargoOfMoving',
      name: '移库入库单',
      path: '/inventory/cargoOfMoving',
      redirect: '/inventory/cargoOfMoving/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'inventoryCargoOfMovingList',
          name: '移库入库列表',
          path: '/inventory/cargoOfMoving/list',
          options: [CREATE_OPTION, DELETE_OPTION, UPDATE_OPTION, READ_OPTION],
          component: () => import('hsu/pages/Inventory/CargoOfMoving/List')
        },
        {
          key: 'inventoryCargoOfMovingCreate',
          name: '新增移库入库单',
          path: '/inventory/cargoOfMoving/create',
          component: () => import('hsu/pages/Inventory/CargoOfMoving/Create')
        },
        {
          key: 'inventoryCargoOfMovingEdit',
          name: '编辑移库入库单',
          path: '/inventory/cargoOfMoving/edit',
          component: () => import('hsu/pages/Inventory/CargoOfMoving/Edit')
        },
        {
          key: 'inventoryCargoOfMovingDetail',
          name: '查看移库入库单',
          path: '/inventory/cargoOfMoving/detail',
          component: () => import('hsu/pages/Inventory/CargoOfMoving/Detail')
        }
      ]
    }
  ]
}

export default inventory
