import { Key } from 'react'
import ReactDOM from 'react-dom'

import { message } from 'antd'
import axios from 'axios'
import { saveAs } from 'file-saver'
import { forOwn, isFunction, isNil, isPlainObject } from 'lodash-es'
import { stringifyUrl } from 'query-string'

import { GenericalKey, KeySet, Noop, Omit2 } from 'common/interface/base'

import { AxiosRequest } from '../interface/crud'

import { BASE_URL, SERVER_URL } from './constant'
import { TOKEN } from './localKey'
import store from './store'

export const isValid = <T>(value: T): value is Exclude<T, null | undefined> => {
  return !isNil(value)
}

/**
 * 判断是否是空对象
 *
 * @param data 对象
 * @param key 键值
 * @returns boolean
 */
export const isEmptyObj = <T extends Record<string, any>>(data?: T) => {
  if (!data) {
    return true
  }
  const keys = Object.keys(data)
  for (const k of keys) {
    if (!isNil(data[k])) {
      return false
    }
  }
  return true
}

/**
 * 判断是否是空数据
 *
 * @param data 数据
 * @param key key
 * @returns boolean
 */
export const isEmpty = <T>(data: T): data is T & (null | undefined) => {
  if (isNil(data)) {
    return true
  }

  if (Array.isArray(data) || typeof data === 'string') {
    return !data.length
  }

  if (isPlainObject(data)) {
    return isEmptyObj(data as Record<string, any>)
  }

  return false
}

/**
 * isEmpty 方法的取反函数
 */
export const isNotEmpty = <T>(
  data: T
): data is Exclude<T, null | undefined> => {
  return !isEmpty(data)
}

/**
 * 判断对象是否是 Promise 类型
 *
 * @param obj 对象
 */
export const isPromise = (obj: unknown): obj is Promise<unknown> =>
  obj instanceof Promise || Promise.resolve(obj) === obj

/**
 * 判断 url 是否是外部路径
 */
export const isExternalUrl = (() => {
  const regexp = /^https?:\/\//
  return (url: string) => regexp.test(url)
})()

/**
 * 数组转成 map
 *
 * @param array 数组
 * @param key key 字段
 * @param keyGenerator key 生成器
 */
export const array2Map = <
  T extends Record<GenericalKey, any>,
  K extends keyof T
>(
  array: T[],
  key?: K | ((item: T, index: number) => any)
) => {
  const result: Record<GenericalKey, T> = {}

  array.forEach((item, index) => {
    const keyValue = key
      ? typeof key === 'function'
        ? key(item, index)
        : item[key]
      : index + ''
    result[keyValue] = item
  })
  return result
}

/**
 * 数组型树转 Map
 *
 * @param array 源数据
 */
export function arrayTree2Map<
  T extends { [key: string]: any; id?: string; children?: T[] }
>(array: T[], key?: keyof T): Record<string, T>

export function arrayTree2Map<
  T extends { [key: string]: any; id?: string; children?: T[] },
  F extends (item: T) => any
>(array: T[], key?: keyof T, resolve?: F): Record<string, ReturnType<F>>

export function arrayTree2Map<
  T extends { [key: string]: any; id?: string; children?: T[] },
  F extends (item: T) => any
>(array: T[], key: keyof T = 'id', resolve?: F) {
  const result: Record<string, any> = {}
  const run = (data: T[], key: string) => {
    data.forEach(item => {
      result[item[key]] = resolve ? resolve(item) : item
      if (item.children) {
        run(item.children, key)
      }
    })
  }
  run(array, key.toString())
  return result
}

/**
 * 树形数组转为一维数组
 *
 * @param data 数据源
 */
export const flattenArrayTree = <T extends { children?: T[] }>(data: T[]) => {
  return data.reduce((a, b) => {
    a.push(b)
    if (b.children) {
      a.push(...flattenArrayTree(b.children))
    }
    return a
  }, [] as T[])
}

/**
 * 从对象中获取指定集合的值，返回新的对象
 *
 * lodash#pick 的 ts 签名有问题，不会自动补全字段，不要用
 *
 * @param data 数据源
 * @param keys 键集合
 */
export const pick = <T, K extends keyof T>(
  data?: T,
  ...keys: K[]
): Pick<T, K> => {
  const result = {} as any

  if (!(data && keys)) {
    return result
  }

  for (const key of keys) {
    result[key] = data[key]
  }

  return result
}

/**
 * 从对象中排除指定集合的值，返回新的对象
 *
 * lodash#omit 的 ts 签名有问题，不会自动补全字段，不要用
 *
 * @param data 数据源
 * @param keys 键集合
 */
export const omit = <T, K extends keyof T>(
  data?: T,
  ...keys: K[]
): Omit2<T, K> => {
  const result = {} as any

  if (!data) {
    return result
  }

  if (!keys) {
    return data || result
  }

  forOwn(data, (value, key) => {
    if (!keys.includes(key as K)) {
      result[key] = value
    }
  })
  return result
}

/**
 * 从数组中移除指定项
 *
 * @param array 数组
 * @param item 子项
 * @return index 被移除项的下标
 */
export const remove = <T>(array: T[], item: T | number): number => {
  const index = typeof item === 'number' ? item : array.indexOf(item)
  if (index > -1) {
    array.splice(index, 1)
  }
  return index
}

/**
 * 把子项插入到数组指定的位置，如果 index 大于数组的长度，则添加到最后
 *
 * @param array 数组
 * @param item 子项
 * @param index 下标
 */
export const insert = <T>(array: T[], item: T, index = 0): void => {
  array.splice(index, 0, item)
}

/**
 * 重定向到登陆页面
 */
export const redirect2Login = () => {
  // 清空登陆信息
  redirectPage('/login', {
    redirect: window.location.href.replace(window.location.origin, '')
  })
}

/**
 * 重定向页面
 */
export const redirectPage = (path: string, query?: any) => {
  window.location.href = stringifyUrl({
    url: path.startsWith(BASE_URL)
      ? path
      : `${BASE_URL}/${path}`.replace(/\/+/g, '/'),
    query
  })
}

/**
 * 判断当前是否处于登陆页面
 */
export const isLoginPage = () => {
  return window.location.href.indexOf('/login') > -1
}

/**
 * 判断是否登录
 */
export const checkLogin = () => {
  return !store.get(TOKEN) && !isLoginPage() && redirect2Login()
}

export const wrapApi = <T, P>(
  api?: AxiosRequest<T, P>,
  options?: {
    // 请求失败不要展示错误消息
    silent?: boolean
    // 可取消
    cancelable?: boolean
    // 缓存结果
    cacheable?: boolean
  }
) => {
  const { silent, cancelable, cacheable } = options || {}

  const tokenSource = cancelable ? axios.CancelToken.source() : undefined

  let result: T

  const newApi: AxiosRequest<T, P> = async (params, config) => {
    if (cacheable && result) {
      return result
    }

    if (api) {
      const data = await api(params, {
        silent,
        cancelToken: tokenSource?.token,
        ...config
      })

      if (cacheable) {
        result = data
      }

      return data
    }

    return Promise.resolve(undefined as unknown as T)
  }

  Object.assign(newApi, { tokenSource }, api)

  return newApi
}

/**
 * 解析对应服务的请求路径
 */
export const resolveApiPath = (url?: string) => {
  if (!url) {
    throw new Error('请求地址不能为空')
  }

  if (isExternalUrl(url)) {
    return url
  }

  return SERVER_URL + '/api' + url
}

/**
 * 获取附件的完整地址
 */
export const resolveFileUrl = (url?: string) => SERVER_URL + '/api/auth' + url

/**
 * 下载
 *
 * @param params
 */
export const download = (params: { name?: string; url: string }) => {
  try {
    saveAs(resolveApiPath(params.url), params.name)
  } catch (e) {
    console.error(e)
    message.warn(`${params.name}文件下载失败`)
  }
}

/**
 * 从 object 中获取嵌套路径的 value
 *
 * @param data
 * @param path
 * @param defaultValue
 * @returns
 */
export const getNestedValue = (
  data: Record<string, any>,
  path: string,
  defaultValue?: any
) => {
  let value: any = data
  const keys = path.split('.')
  for (const key of keys) {
    if (value) {
      value = value[key]
    } else {
      value = defaultValue
      break
    }
  }
  return value
}

/**
 * 为 url 中的占位符参数赋值
 */
export const parseVariableUrl = (() => {
  const regexp = /{([^}]+)}/g
  return (url: string, params: any, warning = true) => {
    return url.replace(regexp, ($0, $1) => {
      const result = getNestedValue(params, $1)
      if (warning && isNil(result)) {
        console.warn(`${url} 中的 ${$1} 参数为空`)
      }
      return result
    })
  }
})()

/**
 * 过滤外部传给组件的值
 * @param value 值
 */
export const filterComponentValue = <T extends Record<string, any>>(
  key: string,
  value?: T | T[]
) => {
  if (isNil(value)) {
    return undefined
  }
  if (Array.isArray(value)) {
    return value.filter(item => !!item[key])
  }
  return value[key] ? value : undefined
}

/**
 * 转换成数组格式
 *
 * @param value 值
 */
export const toArray = <T>(value?: T | T[]) => {
  if (isNil(value)) {
    return []
  }
  // 使用 slice 处理 proxy 数组对象
  return Array.isArray(value) ? value.slice() : [value]
}

/**
 * 获取 css 属性的值
 *
 * @param value css 属性值
 */
export const getCssValue = (value?: number | string) => {
  if (isNil(value)) {
    return undefined
  }
  if (typeof value === 'number') {
    return value + 'px'
  }
  return value
}

/**
 * 默认的比较方法
 */
const defaultComparator = (a: any, b: any) => a === b

/**
 * 解析表单中从 picker 传来的值，保持 picker 中的值不变，移除表单中被「取消勾选」的项
 *
 * △ 使用场景：同步 picker 新增的选中项 & 取消勾选的项
 *
 * @param pickerValue picker 中的值
 * @param formValue form 中的值
 * @param comparator 用于比较的方法
 */
export const resolvePickerChangedValue = <
  T extends Record<string, any>,
  R extends Record<string, any>
>(
  pickerValue?: T[],
  formValue?: R[],
  comparator: (value1: T, value2: R) => boolean = defaultComparator
): [T[], R[]] => {
  if (!(pickerValue && pickerValue.length)) {
    return [[], []]
  }

  if (!(formValue && formValue.length)) {
    return [pickerValue, []]
  }

  const oldValue: R[] = []

  const newValue = pickerValue.slice()

  // 组件当前选择的值
  pickerValue.forEach(value1 => {
    for (const value2 of formValue) {
      const flag = comparator(value1, value2)
      if (flag) {
        // 表单中已经存在的项
        oldValue.push(value2)
        remove(newValue, value1)
        break
      }
    }
  })
  return [newValue, oldValue]
}

/**
 * 解析表单中从 picker 传来的值，保持表单中的值不变，追加 picker 中新勾选的项
 *
 * △ 使用场景：同步 picker 新增的选中项，跟当前表单的值会进行过滤（保证选中项的唯一性）
 *
 * @param pickerValue picker 中的值
 * @param formValue form 中的值
 * @param comparator 用于比较的方法
 */
export const resolvePickerAppendedValue = <
  T extends Record<string, any>,
  R extends Record<string, any>
>(
  pickerValue?: T[],
  formValue?: R[],
  comparator: (value1: T, value2: R) => boolean = defaultComparator
): [T[], R[]] => {
  if (!(pickerValue && pickerValue.length)) {
    return [[], []]
  }

  if (!(formValue && formValue.length)) {
    return [pickerValue, []]
  }

  const newValue = pickerValue.filter(a =>
    formValue.some(b => comparator(a, b))
  )
  return [newValue, formValue]
}

/**
 * table 的行进行点击时更新当前所选择的数据
 */
export const removeOrInsertSelectedRow = (options: {
  rowKey: string
  record: any
  selectedKeys: Key[]
  selectedRows: any[]
}) => {
  const { rowKey, record, selectedKeys, selectedRows } = options

  const key = record[rowKey]

  if (selectedKeys.includes(key)) {
    return {
      selectedKeys: selectedKeys.filter(item => item !== key),
      selectedRows: selectedRows.filter(item => item[rowKey] !== key)
    }
  }

  return {
    selectedKeys: [...selectedKeys, key],
    selectedRows: [...selectedRows, record]
  }
}

/**
 * 解析 table 选择项的值
 *
 * @param selectedKeys 选择项的 key 集合
 * @param selectedRows 选择的行集合
 * @param confirmedValue 之前的选择项集合
 * @param rowKey 行的唯一性标识
 */
export const resolveTableSelectedValue = <T extends Record<GenericalKey, any>>(
  selectedKeys: Key[],
  selectedRows: T[],
  confirmedValue: T[],
  rowKey: KeySet<T>
) => {
  const valueMap = array2Map(confirmedValue, rowKey)
  const oldValue = selectedKeys.map(key => valueMap[key]).filter(item => item)
  const newValue = selectedRows.filter(item => item)
  const uniqueOldValue = oldValue.filter(a =>
    newValue.every(b => a[rowKey] !== b[rowKey])
  )
  return [...uniqueOldValue, ...newValue]
}

export const promiseNoop = (...args: any[]) => Promise.resolve(undefined as any)

export const getRequiredApi = <T extends Noop>(api?: T) => {
  return api || (promiseNoop as T)
}

/**
 * 获取字符串的字节大小
 */
export const getByteSize = (str?: string) => {
  return str ? new Blob([str]).size : 0
}

/**
 * 让详情页支持翻页功能
 */
export const createDetailLink = (link: string) => {
  return (
    value: any,
    record: Record<string, any>,
    index: number,
    listData: Record<string, any>[]
  ) => {
    return stringifyUrl({
      url: link,
      query: {
        id: record.id,
        ids: listData.map(item => item.id).toString(),
        status: record.status,
        creatorName: record.creatorName,
        gsImageId: record?.gsImageId,
        paymentType: record?.paymentType,
        subStatus: record?.subStatus,
        sendGs: record?.sendGs
      }
    })
  }
}

export const getTextFromNode = (node: React.ReactNode) => {
  if (!node) {
    return ''
  }

  if (typeof node !== 'object') {
    return node
  }

  const container = document.createElement('div')

  try {
    ReactDOM.render(node as any, container)
  } catch (e) {
    console.error(e)
  }

  return container.innerText
}

export const resolveFunctionProp = <T>(
  prop: T,
  ...args: any[]
): T extends Noop ? ReturnType<T> : T => {
  return isFunction(prop) ? prop(...args) : prop
}
