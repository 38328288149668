import { useMemo } from 'react'

import { Avatar } from 'antd'

import { useTransformSize } from 'common/hooks/useSize'

import { UserAvatorProps } from './interface'

const defaultStyle: React.CSSProperties = {
  backgroundColor: '#fde3cf',
  color: '#f56a00'
}

const UserAvator = (props: UserAvatorProps) => {
  const { className, style, text } = props

  const size = useTransformSize()

  const mergedStyle = useMemo(() => ({ ...defaultStyle, ...style }), [style])

  return (
    <Avatar className={className} size={size} style={mergedStyle}>
      {text?.toUpperCase()}
    </Avatar>
  )
}

export default UserAvator
