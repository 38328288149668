import { Noop } from 'common/interface/base'

import useDebounceFn from './useDebounceFn'

type ThrottleOptions = {
  maxWait?: number
}

const useThrottleFn = <T extends Noop>(fn: T, { maxWait }: ThrottleOptions) => {
  return useDebounceFn<T>(fn, {
    maxWait,
    wait: maxWait,
    leading: true,
    trailing: false
  })
}

export default useThrottleFn
