import { SettingOutlined } from '@ant-design/icons'

import { RouteItem } from 'common/router/interface'
import {
  CREATE_OPTION,
  DELETE_OPTION,
  UPDATE_OPTION,
  READ_OPTION
} from 'common/router/option'

import {
  DEPT_ADD_DEPUTY_OPTION,
  DEPT_ADD_FINANCE_OPTION,
  DEPT_ADD_MANAGER_OPTION,
  DEPT_ADD_PERSON_OPTION
} from 'hsu/pages/System/Dept/lib/option'

const system: RouteItem = {
  key: 'system',
  name: '系统配置',
  path: '/system',
  redirect: '/system/user',
  icon: <SettingOutlined />,
  layout: 'PageLayout',
  children: [
    {
      key: 'user',
      name: '人员管理',
      path: '/system/user',
      redirect: '/system/user/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'userList',
          name: '人员列表',
          path: '/system/user/list',
          options: [CREATE_OPTION, DELETE_OPTION, UPDATE_OPTION, READ_OPTION],
          component: () => import('hsu/pages/System/User/List')
        },
        {
          key: 'userCreate',
          name: '新增人员',
          path: '/system/user/create',
          component: () => import('hsu/pages/System/User/Create')
        },
        {
          key: 'userEdit',
          name: '编辑人员',
          path: '/system/user/edit',
          component: () => import('hsu/pages/System/User/Edit')
        },
        {
          key: 'userDetail',
          name: '人员详情',
          path: '/system/user/detail',
          component: () => import('hsu/pages/System/User/Detail')
        }
      ]
    },
    {
      key: 'dept',
      name: '部门管理',
      path: '/system/dept',
      layout: 'PageLayout',
      options: [
        CREATE_OPTION,
        UPDATE_OPTION,
        DELETE_OPTION,
        DEPT_ADD_MANAGER_OPTION,
        DEPT_ADD_FINANCE_OPTION,
        DEPT_ADD_DEPUTY_OPTION,
        DEPT_ADD_PERSON_OPTION
      ],
      component: () => import('hsu/pages/System/Dept/index')
    },
    {
      key: 'role',
      name: '角色管理',
      path: '/system/role',
      redirect: '/system/role/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'roleList',
          name: '角色列表',
          path: '/system/role/list',
          options: [CREATE_OPTION, DELETE_OPTION, UPDATE_OPTION, READ_OPTION],
          component: () => import('hsu/pages/System/Role/List')
        },
        {
          key: 'roleCreate',
          name: '新增角色',
          path: '/system/role/create',
          component: () => import('hsu/pages/System/Role/Create')
        },
        {
          key: 'roleEdit',
          name: '编辑角色',
          path: '/system/role/edit',
          component: () => import('hsu/pages/System/Role/Edit')
        },
        {
          key: 'roleDetail',
          name: '角色详情',
          path: '/system/role/detail',
          component: () => import('hsu/pages/System/Role/Detail')
        }
      ]
    },
    {
      key: 'menu',
      name: '菜单管理',
      path: '/system/menu',
      component: () => import('hsu/pages/System/Menu/index')
    },
    {
      key: 'dict',
      name: '字典管理',
      path: '/system/dict',
      redirect: '/system/dict/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'dictList',
          name: '字典列表',
          path: '/system/dict/list',
          options: [CREATE_OPTION, DELETE_OPTION, UPDATE_OPTION, READ_OPTION],
          component: () => import('hsu/pages/System/Dict/List')
        },
        {
          key: 'dictCreate',
          name: '新增字典',
          path: '/system/dict/create',
          component: () => import('hsu/pages/System/Dict/Create')
        },
        {
          key: 'dictEdit',
          name: '编辑字典',
          path: '/system/dict/edit',
          component: () => import('hsu/pages/System/Dict/Edit')
        },
        {
          key: 'dictDetail',
          name: '字典详情',
          path: '/system/dict/detail',
          component: () => import('hsu/pages/System/Dict/Detail')
        }
      ]
    },
    {
      key: 'approveConfig',
      name: '审批配置',
      path: '/system/approve/config',
      redirect: '/system/approve/config/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'approveConfigList',
          name: '审批配置列表',
          path: '/system/approve/config/list',
          options: [CREATE_OPTION, DELETE_OPTION, UPDATE_OPTION, READ_OPTION],
          component: () => import('hsu/pages/Contract/Approve/Config/List')
        },
        {
          key: 'approveConfigCreate',
          name: '新增审批配置',
          path: '/system/approve/config/create',
          component: () => import('hsu/pages/Contract/Approve/Config/Create')
        },
        {
          key: 'approveConfigEdit',
          name: '编辑审批配置',
          path: '/system/approve/config/edit',
          component: () => import('hsu/pages/Contract/Approve/Config/Edit')
        },
        {
          key: 'approveConfigDetail',
          name: '审批配置详情',
          path: '/system/approve/config/detail',
          component: () => import('hsu/pages/Contract/Approve/Config/Detail')
        }
      ]
    }
  ]
}

export default system
