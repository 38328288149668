/**
 * @desc 更新当前用户的配置信息
 */
import { UserConfigEntity } from 'api/auth/interface'
import fetcher from 'common/lib/fetcher'
import { MyAxiosRequestConfig } from 'common/interface/crud'

export type UserConfigUpdateCurrentUserConfigParams = UserConfigEntity

export type UserConfigUpdateCurrentUserConfigResponse = UserConfigEntity

export const userConfigUpdateCurrentUserConfigMethod = 'PUT'

export const userConfigUpdateCurrentUserConfigUrl = '/auth/v1/userConfig'

export function userConfigUpdateCurrentUserConfigRequest(
  data?: UserConfigUpdateCurrentUserConfigParams,
  options?: MyAxiosRequestConfig
) {
  return fetcher.request<UserConfigUpdateCurrentUserConfigResponse>({
    url: userConfigUpdateCurrentUserConfigUrl,
    method: userConfigUpdateCurrentUserConfigMethod,
    data,
    ...options
  })
}

userConfigUpdateCurrentUserConfigRequest.method =
  userConfigUpdateCurrentUserConfigMethod
userConfigUpdateCurrentUserConfigRequest.url =
  userConfigUpdateCurrentUserConfigUrl
