import { BarChartOutlined } from '@ant-design/icons'

import { RouteItem } from 'common/router/interface'

/**
 * 报表模块
 */
const report: RouteItem = {
  key: 'report',
  name: '报表管理',
  path: '/report',
  redirect: '/report/vehicleDetail',
  layout: 'BasicLayout',
  icon: <BarChartOutlined />,
  children: [
    {
      key: 'vehicleDetailReport',
      name: '车辆明细',
      path: '/report/vehicleDetail',
      component: () => import('common/components/FineServer/ReportPage'),
      props: {
        params: {
          reportlet: 'hs_Scale.cpt',
          op: 'view'
        }
      }
    }
  ]
}

export default report
