/**
 * @desc undefined
 */
import { OAuth2AccessToken } from 'api/oauth2/interface'
import fetcher from 'common/lib/fetcher'
import { MyAxiosRequestConfig } from 'common/interface/crud'

export type LoginVerifyCodeLoginParams = any

export type LoginVerifyCodeLoginResponse = OAuth2AccessToken

export const loginVerifyCodeLoginMethod = 'POST'

export const loginVerifyCodeLoginUrl = '/oauth2/verifyCodeLogin'

export function loginVerifyCodeLoginRequest(
  data?: LoginVerifyCodeLoginParams,
  options?: MyAxiosRequestConfig
) {
  return fetcher.request<LoginVerifyCodeLoginResponse>({
    url: loginVerifyCodeLoginUrl,
    method: loginVerifyCodeLoginMethod,
    data,
    ...options
  })
}

loginVerifyCodeLoginRequest.method = loginVerifyCodeLoginMethod
loginVerifyCodeLoginRequest.url = loginVerifyCodeLoginUrl
