import { useEffect, useMemo } from 'react'

// eslint-disable-next-line no-restricted-imports
import { createForm, Form, IFormProps } from '@formily/core'

import { isValid } from 'common/lib/util'

type UseCreateFormProps<T extends Record<string, any>> = IFormProps<T> & {
  form?: Form<T>
}

export const createFormilyForm = <T extends Record<string, any>>(
  options?: IFormProps<T>
) => {
  const form = createForm(options)
  const { setValues } = form

  form.setValues = (values, strategy = 'shallowMerge') => {
    setValues.call(form, values, strategy)
  }

  return form
}

export const useCreateForm = <T extends Record<string, any>>(
  props?: UseCreateFormProps<T>
) => {
  const {
    form,
    values,
    initialValues,
    pattern,
    display,
    hidden,
    visible,
    editable,
    disabled,
    readOnly,
    readPretty,
    effects
  } = props || {}

  const finalForm = useMemo(() => {
    if (form) {
      return form
    }

    return createFormilyForm(props)
  }, [form])

  useEffect(() => {
    if (isValid(values)) {
      finalForm.setValues(values)
    }
  }, [finalForm, values])

  useEffect(() => {
    if (isValid(initialValues)) {
      finalForm.setInitialValues(initialValues)
    }
  }, [finalForm, initialValues])

  useEffect(() => {
    if (isValid(pattern)) {
      finalForm.setPattern(pattern)
    }
  }, [finalForm, pattern])

  useEffect(() => {
    if (isValid(display)) {
      finalForm.setDisplay(display)
    }
  }, [finalForm, display])

  useEffect(() => {
    if (isValid(hidden)) {
      finalForm.setDisplay(hidden ? 'hidden' : 'visible')
    }
  }, [finalForm, hidden])

  useEffect(() => {
    if (isValid(visible)) {
      finalForm.setDisplay(visible ? 'visible' : 'none')
    }
  }, [finalForm, visible])

  useEffect(() => {
    if (isValid(editable)) {
      finalForm.setPattern(editable ? 'editable' : 'readPretty')
    }
  }, [finalForm, editable])

  useEffect(() => {
    if (isValid(disabled)) {
      finalForm.setPattern(disabled ? 'disabled' : 'editable')
    }
  }, [finalForm, disabled])

  useEffect(() => {
    if (isValid(readOnly)) {
      finalForm.setPattern(readOnly ? 'readOnly' : 'editable')
    }
  }, [finalForm, readOnly])

  useEffect(() => {
    if (isValid(readPretty)) {
      finalForm.setPattern(readPretty ? 'readPretty' : 'editable')
    }
  }, [finalForm, readPretty])

  useEffect(() => {
    if (isValid(effects)) {
      finalForm.setEffects(effects)
    }
  }, [finalForm, effects])

  return finalForm
}
