import {
  useLayoutEffect,
  useRef,
  useMemo,
  useContext,
  createContext
} from 'react'

import { Grid } from '@formily/grid'
import { observer } from '@formily/react'
import { markRaw } from '@formily/reactive'
import cls from 'classnames'

import { pickDataProps } from 'common/@formily/__builtins__/pickDataProps'
import { usePrefixCls } from 'common/@formily/hooks/clazz'

import useFormLayout from '../FormLayout/hooks/useFormLayout'

import './index.less'
import { FormGridProps, GridColumnProps } from './interface'

const FormGridContext = createContext<Grid<HTMLElement>>({} as any)

export const createFormGrid = (props: FormGridProps) => {
  return markRaw(new Grid(props))
}

export const useFormGrid = () => useContext(FormGridContext)

export const FormGrid = observer(
  ({ children, className, style, ...props }: FormGridProps) => {
    const layout = useFormLayout()
    const options = {
      columnGap: layout?.gridColumnGap ?? 8,
      rowGap: layout?.gridRowGap ?? 4,
      ...props
    }
    const grid = useMemo(
      () => markRaw(options?.grid ? options.grid : new Grid(options)),
      [Grid.id(options)]
    )

    const ref = useRef<HTMLDivElement>(null)
    const prefixCls = usePrefixCls('formily-grid', props)
    const dataProps = pickDataProps(props)

    useLayoutEffect(() => {
      if (ref.current) {
        return grid.connect(ref.current)
      }
    }, [grid])

    return (
      <FormGridContext.Provider value={grid}>
        <div
          {...dataProps}
          ref={ref}
          className={cls(`${prefixCls}-layout`, className)}
          style={{
            ...style,
            gridTemplateColumns: grid.templateColumns,
            gap: grid.gap
          }}
        >
          {children}
        </div>
      </FormGridContext.Provider>
    )
  },
  {
    forwardRef: true
  }
)

export const GridColumn = observer(
  ({ gridSpan = 1, children, ...props }: GridColumnProps) => {
    return (
      <div {...props} data-grid-span={gridSpan} style={props.style}>
        {children}
      </div>
    )
  }
)
