import { Layout } from 'antd'
import classNames from 'classnames'

import { BaseProps } from 'common/interface/component'

import LayoutHeader from '../LayoutHeader'
import { LayoutProvider } from '../LayoutProvider'
import PageSider from '../LayoutSider'

import './index.less'

export type LayoutSkeletonProps = BaseProps & {
  children?: React.ReactNode
}

const LayoutSkeleton = (props: LayoutSkeletonProps) => {
  const { className, children } = props

  const rootPrefixCls = 'layout-skeleton'

  const rootClassName = classNames(rootPrefixCls, className)

  const mainPrefixCls = `${rootPrefixCls}-main`

  return (
    <Layout className={rootClassName}>
      <LayoutHeader />
      <Layout>
        <LayoutProvider>
          <PageSider />
          <Layout.Content className={mainPrefixCls}>{children}</Layout.Content>
        </LayoutProvider>
      </Layout>
    </Layout>
  )
}

export default LayoutSkeleton
