import { RoleEntity, FileEntity, LoginInfo } from 'api/auth/interface'

import { hasRoles, isAdmin } from 'common/lib/permission'
import { omit } from 'common/lib/util'

class UserModel {
  account?: string

  deptCode?: string

  deptId?: string

  langchaoCode?: string

  deptName?: string

  userId?: string

  interUser?: boolean

  userName?: string

  tenantId?: string

  permissions: string[] = []

  roles: RoleEntity[] = []

  fileEntityList?: FileEntity[]

  isAdmin = false

  loaded = false

  constructor(props?: LoginInfo) {
    if (!props) {
      return
    }

    const { user, roles = [], permissions = [], fileEntityList } = props
    Object.assign(this, omit(user, 'id', 'name'))
    this.loaded = true
    this.userId = user?.id
    this.userName = user?.name
    this.account = user?.account
    this.roles = roles
    this.permissions = permissions.sort()
    this.fileEntityList = fileEntityList
    this.isAdmin = isAdmin(this.roles)
  }

  hasRoles = (roles: string[]) => {
    return hasRoles(this.roles, roles)
  }

  hasPermission = (permission: string) => {
    return this.permissions.includes(permission)
  }
}

export default UserModel
