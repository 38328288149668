import { useEffect, useState } from 'react'

import { Input, InputProps, Tooltip } from 'antd'
import classNames from 'classnames'

import { kaptchaCreateVerifyCodeUrl } from 'api/oauth2/kaptcha/createVerifyCode'

import { useSize } from 'common/hooks/useSize'
import { resolveApiPath } from 'common/lib/util'

import './index.less'

const getApiPath = () => {
  return `${resolveApiPath(kaptchaCreateVerifyCodeUrl)}?timestamp=${Date.now()}`
}

const VerifyCodeInput = ({ className, ...restProps }: InputProps) => {
  const [src, setSrc] = useState('')

  const size = useSize()

  const refreshCode = () => {
    setSrc(getApiPath())
  }

  useEffect(() => {
    setSrc(getApiPath())
  }, [])

  const rootPrefixCls = 'verify-code-input'
  const rootCls = classNames(rootPrefixCls, className)
  const codeCls = classNames(`${rootPrefixCls}-code`, {
    [`${rootPrefixCls}-code-sm`]: size === 'small',
    [`${rootPrefixCls}-code-lg`]: size === 'large'
  })

  return (
    <Input
      {...restProps}
      allowClear
      className={rootCls}
      suffix={
        <Tooltip title='点击刷新验证码'>
          <img
            alt='验证码'
            className={codeCls}
            src={src}
            onClick={refreshCode}
          />
        </Tooltip>
      }
    />
  )
}

export default VerifyCodeInput
