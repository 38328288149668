import { DollarOutlined } from '@ant-design/icons'

import { RouteItem } from 'common/router/interface'
import {
  CREATE_OPTION,
  DELETE_OPTION,
  UPDATE_OPTION,
  READ_OPTION,
  INVALID_OPTION,
  AUDIT_OPTION,
  AUDIT_CANCEL_OPTION
} from 'common/router/option'

import {
  INVOICE_APPLY_CONFIRM_OPTION,
  INVOICE_APPLY_RED_OPTION
} from 'hsu/pages/Finance/InvoiceApply/option'

const finance: RouteItem = {
  key: 'finance',
  name: '财务管理',
  path: '/finance',
  redirect: '/finance/receipt',
  layout: 'PageLayout',
  icon: <DollarOutlined />,
  children: [
    {
      key: 'financeReceipt',
      name: '收款单',
      path: '/finance/receipt',
      redirect: '/finance/receipt/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'financeReceiptList',
          name: '收款单列表',
          path: '/finance/receipt/list',
          options: [CREATE_OPTION, DELETE_OPTION, UPDATE_OPTION, READ_OPTION],
          component: () => import('hsu/pages/Finance/Receipt/List')
        },
        {
          key: 'financeReceiptCreate',
          name: '新增收款单',
          path: '/finance/receipt/create',
          component: () => import('hsu/pages/Finance/Receipt/Create')
        },
        {
          key: 'financeReceiptEdit',
          name: '编辑收款单',
          path: '/finance/receipt/edit',
          component: () => import('hsu/pages/Finance/Receipt/Edit')
        },
        {
          key: 'financeReceiptDetail',
          name: '收款单详情',
          path: '/finance/receipt/detail',
          component: () => import('hsu/pages/Finance/Receipt/Detail')
        }
      ]
    },
    {
      key: 'financeFundAdjustment',
      name: '调整单',
      path: '/finance/fundAdjustment',
      redirect: '/finance/fundAdjustment/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'financeFundAdjustmentList',
          name: '调整单列表',
          path: '/finance/fundAdjustment/list',
          options: [CREATE_OPTION, DELETE_OPTION, UPDATE_OPTION, READ_OPTION],
          component: () => import('hsu/pages/Finance/FundAdjustment/List')
        },
        {
          key: 'financeFundAdjustmentCreate',
          name: '新增调整单',
          path: '/finance/fundAdjustment/create',
          component: () => import('hsu/pages/Finance/FundAdjustment/Create')
        },
        {
          key: 'financeFundAdjustmentEdit',
          name: '编辑调整单',
          path: '/finance/fundAdjustment/edit',
          component: () => import('hsu/pages/Finance/FundAdjustment/Edit')
        },
        {
          key: 'financeFundAdjustmentDetail',
          name: '调整单详情',
          path: '/finance/fundAdjustment/detail',
          component: () => import('hsu/pages/Finance/FundAdjustment/Detail')
        }
      ]
    },
    {
      key: 'financePayment',
      name: '付款单',
      path: '/finance/payment',
      redirect: '/finance/payment/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'financePaymentList',
          name: '付款单列表',
          path: '/finance/payment/list',
          options: [
            CREATE_OPTION,
            DELETE_OPTION,
            UPDATE_OPTION,
            READ_OPTION,
            INVALID_OPTION
          ],
          component: () => import('hsu/pages/Finance/Payment/List')
        },
        {
          key: 'financePaymentCreate',
          name: '新增付款单',
          path: '/finance/payment/create',
          component: () => import('hsu/pages/Finance/Payment/Create')
        },
        {
          key: 'financePaymentEdit',
          name: '编辑付款单',
          path: '/finance/payment/edit',
          component: () => import('hsu/pages/Finance/Payment/Edit')
        },
        {
          key: 'financePaymentDetail',
          name: '付款单详情',
          path: '/finance/payment/detail',
          component: () => import('hsu/pages/Finance/Payment/Detail')
        }
      ]
    },
    {
      key: 'invoiceRegistration',
      name: '发票登记',
      path: '/invoice/registration',
      redirect: '/invoice/registration/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'invoiceRegistrationList',
          name: '发票登记列表',
          path: '/invoice/registration/list',
          options: [
            CREATE_OPTION,
            DELETE_OPTION,
            UPDATE_OPTION,
            READ_OPTION,
            AUDIT_OPTION,
            AUDIT_CANCEL_OPTION
          ],
          component: () => import('hsu/pages/Finance/InvoiceRegistration/List')
        },
        {
          key: 'invoiceRegistrationCreate',
          name: '新增发票登记',
          path: '/invoice/registration/create',
          component: () =>
            import('hsu/pages/Finance/InvoiceRegistration/Create')
        },
        {
          key: 'invoiceRegistrationEdit',
          name: '编辑发票登记',
          path: '/invoice/registration/edit',
          component: () => import('hsu/pages/Finance/InvoiceRegistration/Edit')
        },
        {
          key: 'invoiceRegistrationDetail',
          name: '发票登记详情',
          path: '/invoice/registration/detail',
          component: () =>
            import('hsu/pages/Finance/InvoiceRegistration/Detail')
        }
      ]
    },
    {
      key: 'invoiceApplication',
      name: '开票申请',
      path: '/invoice/application',
      redirect: '/invoice/application/list',
      hideChildrenInMenu: true,
      children: [
        {
          key: 'invoiceApplicationList',
          name: '开票申请列表',
          path: '/invoice/application/list',
          options: [
            CREATE_OPTION,
            DELETE_OPTION,
            UPDATE_OPTION,
            READ_OPTION,
            AUDIT_OPTION,
            AUDIT_CANCEL_OPTION,
            INVOICE_APPLY_CONFIRM_OPTION,
            INVOICE_APPLY_RED_OPTION
          ],
          component: () => import('hsu/pages/Finance/InvoiceApply/List')
        },
        {
          key: 'invoiceApplicationCreate',
          name: '新增开票申请',
          path: '/invoice/application/create',
          component: () => import('hsu/pages/Finance/InvoiceApply/Create')
        },
        {
          key: 'invoiceApplicationEdit',
          name: '编辑开票申请',
          path: '/invoice/application/edit',
          component: () => import('hsu/pages/Finance/InvoiceApply/Edit')
        },
        {
          key: 'invoiceApplicationDetail',
          name: '开票申请详情',
          path: '/invoice/application/detail',
          component: () => import('hsu/pages/Finance/InvoiceApply/Detail')
        }
      ]
    }
  ]
}

export default finance
