import { RoleEntity } from 'api/auth/interface'

import UserModel from 'common/context/CurrentUser/UserModel'
import { resolveOptionKey } from 'common/router/util'

import { ROLE_SYSTEM_ADMIN_CODE } from './constant'
import { isEmpty } from './util'

export const isAdmin = (roles?: RoleEntity[]) => {
  return !!roles?.some(role => role.code === ROLE_SYSTEM_ADMIN_CODE)
}

/**
 * 判断用户是否拥有所需要的角色
 *
 * @param roles 角色列表
 * @returns boolean
 */
export const hasRoles = (roleEntities: RoleEntity[], roles: string[]) => {
  const allRoles = roleEntities?.map(item => item.code)

  if (isEmpty(allRoles)) {
    return false
  }

  return roles.every(item => allRoles.includes(item))
}

/**
 * 判断用户是否有按钮对应的操作权限
 *
 * @param identify 按钮权限标识
 * @param pageKey 按钮所在页面的 route key
 * @param user 当前用户
 * @returns boolean
 */
export const isAuthorizedBtn = (
  identify?: string,
  pageKey?: string,
  user?: UserModel
) => {
  if (!identify) {
    return true
  }

  if (!(pageKey && user?.permissions)) {
    return false
  }

  const optionKey = resolveOptionKey(pageKey, identify)

  return user.isAdmin || user.hasPermission(optionKey)
}
