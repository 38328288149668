import { Link, useHistory } from 'react-router-dom'

import { BreadcrumbProps, PageHeader as AntdPageHeader } from 'antd'
import { last } from 'lodash-es'

import { useLayout } from '../LayoutProvider/context'

const itemRender: BreadcrumbProps['itemRender'] = route => {
  return <Link to={route.path}>{route.breadcrumbName}</Link>
}

const PageHeader = () => {
  const history = useHistory()

  const { matches } = useLayout()

  const title = last(matches)?.name

  const routes: BreadcrumbProps['routes'] = matches.map(item => ({
    path: item.path || '/',
    breadcrumbName: item.name
  }))

  return (
    <AntdPageHeader
      breadcrumb={{
        routes,
        itemRender
      }}
      ghost={false}
      title={title}
      onBack={history.goBack}
    />
  )
}

export default PageHeader
