import { useHistory } from 'react-router-dom'

import { LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { MenuProps, Spin } from 'antd'

import UserAvator from 'common/components/UserAvator'
import { useCurrentUser } from 'common/context/CurrentUser'

import LayoutHeaderDropdown from './Dropdown'

import './Avator.less'

const keys = ['user', 'logout'] as const

type Keys = (typeof keys)[number]

const LayoutHeaderAvator = () => {
  const { userName, deptName } = useCurrentUser()

  const history = useHistory()

  const onClick: MenuProps['onClick'] = info => {
    const key = info.key as Keys
    switch (key) {
      case 'user':
        history.push('/user')
        break
      case 'logout':
        history.push('/login')
        break
    }
  }

  const items: MenuProps['items'] = [
    {
      key: keys[0],
      icon: <UserOutlined />,
      label: '个人中心'
    },
    {
      key: keys[1],
      icon: <LogoutOutlined />,
      label: '退出登录'
    }
  ]
  return userName ? (
    <LayoutHeaderDropdown
      menu={{
        className: 'layout-header-avator__menu',
        items,
        onClick
      }}
    >
      <span className='layout-header-avator__action layout-header-avator__account'>
        <UserAvator text={userName[0]} />
        <span>{userName}</span>
        <span>{deptName ? `「${deptName}」` : ''}</span>
      </span>
    </LayoutHeaderDropdown>
  ) : (
    <span className='layout-header-avator__action layout-header-avator__account'>
      <Spin
        size='small'
        style={{
          marginLeft: 8,
          marginRight: 8
        }}
      />
    </span>
  )
}

export default LayoutHeaderAvator
