import { useState } from 'react'

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import classNames from 'classnames'

import { useProjectConfig } from 'common/context/ProjectConfig'
import { useSize } from 'common/hooks/useSize'

import LayoutSiderMenu from './Menu'

import './index.less'

const { Sider } = Layout

const COLLAPSED_WIDTH = 48

const NORMAL_WIDTH = 208

const getSiderWidth = (collapsed: boolean) =>
  collapsed ? COLLAPSED_WIDTH : NORMAL_WIDTH

const LayoutSider = () => {
  const size = useSize()

  const { theme, defaultCollapsed = false } = useProjectConfig()

  const [collapsed, setCollapsed] = useState(defaultCollapsed)

  const siderWidth = getSiderWidth(collapsed)

  const rootPrefixCls = 'layout-sider'

  const rootClassName = classNames(rootPrefixCls, {
    [`${rootPrefixCls}-sm`]: size === 'small'
  })

  return (
    <>
      <div
        style={{
          width: siderWidth,
          overflow: 'hidden',
          flex: `0 0 ${siderWidth}px`,
          maxWidth: siderWidth,
          minWidth: siderWidth,
          transition: `background-color 0.3s, min-width 0.3s, max-width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)`
        }}
      />
      <Sider
        collapsible
        className={rootClassName}
        collapsed={collapsed}
        collapsedWidth={COLLAPSED_WIDTH}
        theme={theme}
        trigger={null}
        width={NORMAL_WIDTH}
        onCollapse={setCollapsed}
      >
        <div className='layout-sider-content'>
          <div
            style={{
              flex: 1,
              overflowY: 'auto',
              overflowX: 'hidden'
            }}
          >
            <LayoutSiderMenu theme={theme} />
          </div>
          <div className='layout-sider-links'>
            <Menu
              className='layout-sider-link-menu'
              inlineIndent={16}
              items={[
                {
                  key: 'collapseButton',
                  className: 'layout-sider-collapsed-button',
                  onClick: () => {
                    setCollapsed(!collapsed)
                  },
                  label: collapsed ? (
                    <MenuUnfoldOutlined />
                  ) : (
                    <MenuFoldOutlined />
                  )
                }
              ]}
              mode='inline'
              openKeys={[]}
              selectedKeys={[]}
              theme={theme}
            />
          </div>
        </div>
      </Sider>
    </>
  )
}

export default LayoutSider
