/**
 * 页面上的操作
 */

import { OptionItem } from 'common/router/interface'

export const SALE_BILL_ADJUST_BTN = 'saleBillAdjustBtn'

export const SALE_BILL_ADJUST_BTN_OPTION: OptionItem = {
  key: SALE_BILL_ADJUST_BTN,
  name: '调整'
}
