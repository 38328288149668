import { BASE_URL, PROJECT_TITLE } from 'common/lib/constant'

import './LeftContent.less'

const LayoutHeaderLeftContent = () => {
  return (
    <div className='layout-header-left-content'>
      <a className='layout-header-left-content__link' href={`${BASE_URL}`}>
        <img
          alt='logo'
          className='layout-header-left-content__logo'
          src={`${BASE_URL}logo.png`}
        />
        <h1 className='layout-header-left-content__title'> {PROJECT_TITLE}</h1>
      </a>
    </div>
  )
}

export default LayoutHeaderLeftContent
