import { useEffect, useMemo, useState } from 'react'

import { Menu, MenuProps } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'

import AdvanceLink from 'common/components/AdvanceLink'
import { isExternalUrl, isNotEmpty } from 'common/lib/util'
import { RouteItem } from 'common/router/interface'

import { useLayout } from '../LayoutProvider/context'

const filterMenus = (menus: RouteItem[]): RouteItem[] => {
  return menus
    .filter(item => item.hideInMenu !== true)
    .map(item => ({
      ...item,
      children:
        item.children && item.hideChildrenInMenu !== true
          ? filterMenus(item.children)
          : undefined
    }))
}

const transformMenuItems = (menus: RouteItem[]): ItemType[] => {
  return menus.map(({ key, name, path = '/', icon, children }) => {
    const reactKey = key || path

    return isNotEmpty(children)
      ? {
          key: reactKey,
          icon,
          label: name,
          children: transformMenuItems(children)
        }
      : {
          key: reactKey,
          icon,
          label: isExternalUrl(path) ? (
            <a href={path} target='_blank'>
              {name}
            </a>
          ) : (
            <AdvanceLink to={path}>{name}</AdvanceLink>
          )
        }
  })
}

const LayoutSiderMenu = (props: MenuProps) => {
  const { matches, menus } = useLayout()

  const filteredMenu = useMemo(() => filterMenus(menus), [menus])

  const menuItems = useMemo(
    () => transformMenuItems(filteredMenu),
    [filteredMenu]
  )

  const [openKeys, setOpenKeys] = useState<string[]>()

  const [selectedKey, setSelectedKey] = useState<string[]>()

  useEffect(() => {
    const matchedKeys = matches.map(item => item.key)

    setOpenKeys(matchedKeys)
    setSelectedKey(matchedKeys)
  }, [matches])

  return (
    <Menu
      items={menuItems}
      mode='inline'
      openKeys={openKeys}
      selectedKeys={selectedKey}
      onOpenChange={setOpenKeys as any}
      {...props}
    />
  )
}

export default LayoutSiderMenu
