import { createContext } from 'react'

import { GlobalRouterContextState, PageRouterContextState } from '../interface'

const staticInitialState: GlobalRouterContextState = {
  routes: [],
  menus: [],
  authorizedRoutes: [],
  authorizedMenus: [],
  assignableMenus: [],
  wholePermissions: []
}

export const GlobalRouterContext =
  createContext<GlobalRouterContextState>(staticInitialState)

export const PageRouterContext = createContext<PageRouterContextState>(
  {} as any
)
