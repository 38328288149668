import { CacheKey, CacheManagerOptions, CacheValueOptions } from './interface'

const DEFAULT_CACHE_TIME = -1

class CacheValue {
  // 单位：ms
  cacheTime: number

  private value: any

  private timestamp = Date.now()

  constructor(options?: CacheValueOptions) {
    this.cacheTime = options?.cacheTime || DEFAULT_CACHE_TIME
    this.value = options?.value
  }

  isExpired() {
    if (this.cacheTime < 0) {
      return false
    }

    return Date.now() - this.timestamp >= this.cacheTime
  }

  getValue() {
    return this.value
  }

  setValue(value: any) {
    this.value = value
    this.timestamp = Date.now()
  }
}

class CacheManager {
  private defaultCacheTime?: number
  private cache: Map<CacheKey, CacheValue> = new Map()

  constructor(options?: CacheManagerOptions) {
    this.defaultCacheTime = options?.defaultCacheTime
  }

  private checkExpired() {
    this.cache.forEach((value, key) => {
      if (value.isExpired()) {
        this.cache.delete(key)
      }
    })
  }

  init(key: CacheKey, options?: CacheValueOptions) {
    if (!this.cache.has(key)) {
      const cacheValue = new CacheValue({
        cacheTime: this.defaultCacheTime,
        ...options
      })
      this.cache.set(key, cacheValue)
    }
  }

  set(key: CacheKey, value: any) {
    const cacheValue = this.cache.get(key)
    if (cacheValue) {
      cacheValue.setValue(value)
    } else {
      this.init(key, { value })
    }
  }

  get(key?: CacheKey) {
    this.checkExpired()

    if (!key) {
      return undefined
    }

    return this.cache.get(key)?.getValue()
  }
}

export default CacheManager
