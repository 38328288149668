import { RefObject } from 'react'

import useBoolean from 'common/hooks/useBoolean'
import useRequest from 'common/hooks/useRequest'

import { CrudModalFormProps } from '../interface'

type UseModalFormProps = Pick<
  CrudModalFormProps,
  | 'form'
  | 'api'
  | 'onSubmit'
  | 'onOk'
  | 'onCancel'
  | 'onSubmitStart'
  | 'onSuccess'
  | 'onFail'
> & {
  ref: RefObject<HTMLButtonElement>
}

const useModalForm = (props: UseModalFormProps) => {
  const {
    api,
    ref,
    form,
    onSubmitStart,
    onSubmit,
    onOk,
    onCancel,
    onSuccess,
    onFail
  } = props

  const [modal, modalActions] = useBoolean(false)

  const closeModal = modalActions.setFalse

  const showModal = modalActions.setTrue

  const { error, loading, run } = useRequest({
    api,
    manual: true,
    onError: onFail,
    onSuccess: onSuccess
  })

  const onModalCancel = () => {
    closeModal()
    onCancel?.()
  }

  const onModalOk = () => {
    form?.submit(async values => {
      // 触发 form 的 autocomplete 功能
      ref.current?.onclick?.(values)
      onOk?.(values)

      if (api) {
        const result = await onSubmitStart?.(values)

        if (result === false) {
          return
        }

        const formData = onSubmit ? await onSubmit(values) : values

        await run(formData)
      }

      closeModal()
    })
  }

  return {
    modal,
    showModal,
    onModalCancel,
    onModalOk,
    submitError: error,
    submitting: loading
  }
}

export default useModalForm
