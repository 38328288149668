import { createContext, useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { userLoginInfoRequest } from 'api/auth/user/loginInfo'

import useRequest from '../../hooks/useRequest'

import UserModel from './UserModel'

/**
 * 不要使用该 Context 中的 permissions 信息，因为是不完整的。
 * 如有需要请使用 {@link RouterContext#wholePermissions}
 */
export type UserContextState = UserModel

const api = userLoginInfoRequest

const defaultUserModel = new UserModel()

const CurrentUserContext = createContext<UserContextState>(defaultUserModel)

export const useCurrentUser = () => useContext(CurrentUserContext)

export const CurrentUserProvider: React.FC = props => {
  const { pathname } = useLocation()

  const { data } = useRequest({
    api,
    manual: false,
    silent: true,
    already: !pathname.includes('/login')
  })

  const userModel = useMemo(() => {
    return data ? new UserModel(data) : defaultUserModel
  }, [data])

  return <CurrentUserContext.Provider {...props} value={userModel} />
}
