import { useMemo, useRef, useState } from 'react'

import {
  LockOutlined,
  MedicineBoxOutlined,
  UserOutlined
} from '@ant-design/icons'
import { Button, message } from 'antd'
import { stringify } from 'query-string'

import { loginVerifyCodeLoginRequest } from 'api/oauth2/login/verifyCodeLogin'

import FormItem from 'common/@formily/components/FormItem'
import Input from 'common/@formily/components/Input'
import Password from 'common/@formily/components/Password'
import { useCreateForm } from 'common/@formily/hooks/form'
import ChangePasswordModal from 'common/components/ChangePassword/Modal'
import { ChangePasswordModalProps } from 'common/components/ChangePassword/Modal/interface'
import CrudSchemaForm from 'common/components/CrudSchemaForm'
import VerifyCodeInput from 'common/components/VerifyCodeInput'
import useBoolean from 'common/hooks/useBoolean'
import useUrlParams from 'common/hooks/useUrlParams'
import { LOCAL_REMEMBER_DATA_KEY } from 'common/lib/constant'
import { TOKEN } from 'common/lib/localKey'
import { checkStrength } from 'common/lib/password'
import store from 'common/lib/store'
import { redirectPage } from 'common/lib/util'

import { LoginFormProps } from './interface'

import './index.less'

const items: LoginFormProps['items'] = [
  {
    name: 'username',
    type: 'string',
    'x-decorator': FormItem,
    'x-component': Input,
    'x-component-props': {
      placeholder: '用户名',
      prefix: <UserOutlined />
    },
    'x-validator': {
      required: true,
      message: '用户名不能为空'
    }
  },
  {
    name: 'password',
    type: 'string',
    'x-decorator': FormItem,
    'x-component': Password,
    'x-component-props': {
      placeholder: '密码',
      prefix: <LockOutlined />
    },
    'x-validator': {
      required: true,
      message: '密码不能为空'
    }
  },
  {
    name: 'verifyCode',
    type: 'string',
    'x-decorator': FormItem,
    'x-component': VerifyCodeInput,
    'x-component-props': {
      placeholder: '验证码',
      prefix: <MedicineBoxOutlined />
    },
    'x-validator': {
      required: true,
      message: '验证码不能为空'
    }
  }
]

const Login = () => {
  // 返回到目标页
  const { redirect } = useUrlParams()

  const [loading, loadingActions] = useBoolean(false)

  const rememberData = useMemo(
    () => store.get<Record<string, any>>(LOCAL_REMEMBER_DATA_KEY),
    []
  )

  const form = useCreateForm({
    initialValues: rememberData
  })

  const [modalProps, setModalProps] = useState({
    userId: '',
    account: '',
    open: false
  })

  const permitThroughRef = useRef<(newPassword?: string) => void>()

  const onSubmit = () => {
    form.submit(async values => {
      try {
        loadingActions.setTrue()

        const res: any = await loginVerifyCodeLoginRequest(
          stringify({
            grant_type: 'password',
            tenant_id: 'hs0002',
            ...values
          }),
          {
            headers: {
              Authorization: 'Basic cHJvbWluZW50LWVhc3Rjb2FsOnByb21pbmVudA=='
            }
          }
        )

        permitThroughRef.current = password => {
          // 设置本地缓存
          store.set(LOCAL_REMEMBER_DATA_KEY, {
            password,
            username: values.username
          })
          store.set(TOKEN, res.access_token)

          // 需要刷新页面，不然有预加载数据的组件无法重新获取数据
          redirectPage(redirect || '/')
        }

        if (checkStrength(values.password)) {
          permitThroughRef.current(values.password)
        } else {
          try {
            const user = JSON.parse(res.userinfo).user
            setModalProps({
              userId: user.id,
              account: user.account,
              open: true
            })
          } catch (e: any) {
            message.error(e.message)
          }
        }
      } catch (e: any) {
        message.error(e.message)
      } finally {
        loadingActions.setFalse()
      }
    })
  }

  const onPasswordChagneSuccess: ChangePasswordModalProps['onSuccess'] =
    data => {
      permitThroughRef.current?.(data.newPass)
    }

  return (
    <>
      <CrudSchemaForm
        className='login'
        form={form}
        items={items}
        name='login'
        onSubmit={onSubmit}
      >
        <Button block htmlType='submit' loading={loading} type='primary'>
          登 录
        </Button>
      </CrudSchemaForm>
      <ChangePasswordModal
        {...modalProps}
        closable={false}
        title='密码强度校验失败，请重置密码'
        onSuccess={onPasswordChagneSuccess}
      />
    </>
  )
}

export default Login
