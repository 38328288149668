import { useRef } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { Alert, Button, Modal, Spin } from 'antd'

import { useCreateForm } from 'common/@formily/hooks/form'
import modalConfig from 'common/config/modal'

import ThrottleButton from '../CrudButton/ThrottleButton'
import CrudSchemaForm from '../CrudSchemaForm'

import useModalForm from './hooks/useModalForm'
import { CrudModalFormProps } from './interface'

import './index.less'

const CrudModalForm = <T extends Record<string, any>>(
  props: CrudModalFormProps<T>
) => {
  const {
    elRef,
    api,
    form,
    initialValues,
    disabled,
    loading = false,
    children,
    content,
    onSubmit,
    onOk,
    onCancel,
    onSuccess,
    onFail,
    /**
     * Modal 属性
     */
    title = '表单',
    width,
    /**
     * Form 属性
     */
    ...schemaFormProps
  } = props

  const submitRef = useRef<HTMLButtonElement>(null)

  const finalForm = useCreateForm({ form, initialValues })

  const {
    submitError,
    submitting,
    modal,
    showModal,
    onModalCancel,
    onModalOk
  } = useModalForm({
    api,
    form: finalForm,
    ref: submitRef,
    onSubmit,
    onOk,
    onCancel,
    onSuccess,
    onFail
  })

  return (
    <div ref={elRef} className='modal-form'>
      <div
        className='modal-form__handler'
        onClick={disabled ? undefined : showModal}
      >
        {children || <Button disabled={disabled} icon={<PlusOutlined />} />}
      </div>
      <Modal
        {...modalConfig}
        footer={[
          <Button key='cancel' onClick={onModalCancel}>
            取 消
          </Button>,
          <ThrottleButton
            key='ok'
            loading={submitting}
            type='primary'
            onClick={onModalOk}
          >
            确 认
          </ThrottleButton>
        ]}
        open={modal}
        title={title}
        width={width ?? 500}
        wrapClassName='modal-form__modal'
        onCancel={onModalCancel}
      >
        {content || (
          <Spin spinning={loading}>
            <CrudSchemaForm form={finalForm} {...schemaFormProps}>
              <button
                ref={submitRef}
                className='modal-form__submit'
                hidden={true}
                type='submit'
              />
            </CrudSchemaForm>
            {submitError && (
              <Alert
                closable={false}
                message={submitError.message}
                type='error'
              />
            )}
          </Spin>
        )}
      </Modal>
    </div>
  )
}

export default CrudModalForm
