import { useEffect, useRef, useState } from 'react'

import { useField } from '@formily/react'
import { InputRef } from 'antd'

const useName = () => {
  const field = useField()

  const [name, setName] = useState<string>()
  const ref = useRef<InputRef>(null)

  useEffect(() => {
    const formName = ref.current?.input?.form?.name || ''

    setName(`${formName}_${field.path.toString()}`)
  }, [field.path])

  return {
    name,
    ref
  }
}

export default useName
