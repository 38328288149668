import useGlobalRouter from 'common/router/hooks/useGlobalRouter'

import { LayoutContext } from './context'
import useMatches from './hooks/useMatches'

export const LayoutProvider = (props: any) => {
  const { authorizedMenus } = useGlobalRouter()

  const matches = useMatches(authorizedMenus)

  return (
    <LayoutContext.Provider
      {...props}
      value={{ matches, menus: authorizedMenus }}
    />
  )
}
