import { useMemo } from 'react'

import { connect, mapReadPretty } from '@formily/react'
import { InputNumber as AtndInputNumber } from 'antd'
import classNames from 'classnames'

import { formatUtil } from 'common/lib/number'

import PreviewText from '../../shared/PreviewText'

import { InputNumberProps } from './interface'

const InputNumber = (props: InputNumberProps) => {
  const { className, ...restProps } = props

  const rootCls = classNames('input-number', className)

  return (
    <AtndInputNumber
      className={rootCls}
      controls={false}
      keyboard={false}
      {...restProps}
    />
  )
}

export default connect(
  InputNumber,
  mapReadPretty((props: InputNumberProps) => {
    const { value, precision, displayPrecision } = props
    const text = useMemo(
      () =>
        typeof value === 'object' && !value
          ? '-'
          : formatUtil.decimal(value, displayPrecision || precision),
      [value, displayPrecision, precision]
    )

    return <PreviewText value={text} />
  })
)
