/**
 * @desc 根据id查询
 */
import { CustomerProfileEntity } from 'api/hsu/interface'
import fetcher from 'common/lib/fetcher'
import { MyAxiosRequestConfig } from 'common/interface/crud'

export type CustomerProfileGetByIdParams = Partial<{
  /** id */
  id: string
}>

export type CustomerProfileGetByIdResponse = CustomerProfileEntity

export const customerProfileGetByIdMethod = 'GET'

export const customerProfileGetByIdUrl = '/hsu/v1/customer/profile/byId/{id}'

export function customerProfileGetByIdRequest(
  params?: CustomerProfileGetByIdParams,
  options?: MyAxiosRequestConfig
) {
  return fetcher.request<CustomerProfileGetByIdResponse>({
    url: customerProfileGetByIdUrl,
    method: customerProfileGetByIdMethod,
    params,
    ...options
  })
}

customerProfileGetByIdRequest.method = customerProfileGetByIdMethod
customerProfileGetByIdRequest.url = customerProfileGetByIdUrl
