import { Dispatch, SetStateAction, useRef } from 'react'

import usePersistFn from './usePersistFn'
import useUnmount from './useUnmount'

const useSafeAction = <T>(
  fn: Dispatch<SetStateAction<T>>
): Dispatch<SetStateAction<T>> => {
  const ref = useRef(false)

  useUnmount(() => {
    ref.current = true
  })

  return usePersistFn(patch => {
    if (ref.current === false) {
      fn(patch)
    }
  })
}

export default useSafeAction
