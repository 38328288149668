import { useCallback, useState } from 'react'

import useSafeAction from './useSafeAction'

const useSafeState = <T extends Record<string, any>>(
  initialState: T = {} as T
): [T, (patch: Partial<T> | ((prevState: T) => Partial<T>)) => void] => {
  const [state, setState] = useState<T>(initialState)

  const setMergeState = useCallback(patch => {
    setState(prevState => ({
      ...prevState,
      ...(typeof patch === 'function' ? patch(prevState) : patch)
    }))
  }, [])

  const setSafeState = useSafeAction(setMergeState)

  return [state, setSafeState]
}

export default useSafeState
