import { useMemo, useState } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { Button, Modal, Tree } from 'antd'
import { DataNode } from 'antd/es/tree'

import useBoolean from 'common/hooks/useBoolean'
import useGlobalRouter from 'common/router/hooks/useGlobalRouter'
import { RouteItem } from 'common/router/interface'

type MenuPickerProps = {
  value?: string[]
  onChange: (value: string[]) => void
}

const cookTreeData = (data?: RouteItem[]): DataNode[] | undefined =>
  data
    ?.filter(item => item.hideInMenu !== true)
    .map(item => ({
      key: item.key + '',
      title: item.name,
      selectable: false,
      checkable: !item.children || item.hideChildrenInMenu,
      children:
        item.hideChildrenInMenu !== true
          ? cookTreeData(item.children)
          : undefined
    }))

const MenuPicker = (props: MenuPickerProps) => {
  const { value, onChange } = props

  const { routes } = useGlobalRouter()

  const [modal, modalActions] = useBoolean(false)

  const [checkedKeys, setCheckedKeys] = useState(value || [])

  const treeData = useMemo(() => cookTreeData(routes), [routes])

  const onCheck = ({ checked }: any) => {
    setCheckedKeys(checked.filter((item: any) => !!item))
  }

  const onOk = () => {
    modalActions.setFalse()
    onChange(checkedKeys)
  }

  return (
    <div className='custom-entry-menu-picker'>
      <Button
        icon={<PlusOutlined />}
        type='primary'
        onClick={modalActions.setTrue}
      >
        添 加
      </Button>
      <Modal
        bodyStyle={{ height: '520px', overflow: 'auto' }}
        open={modal}
        title='设置常用入口'
        onCancel={modalActions.setFalse}
        onOk={onOk}
      >
        <Tree
          blockNode
          checkable
          checkStrictly
          defaultExpandAll
          checkedKeys={checkedKeys}
          treeData={treeData}
          onCheck={onCheck}
        />
      </Modal>
    </div>
  )
}

export default MenuPicker
