import { useMemo } from 'react'

import { Button } from 'antd'

import { UserConfigEntity } from 'api/auth/interface'

import FormItem from 'common/@formily/components/FormItem'
import InputNumber from 'common/@formily/components/InputNumber'
import Switch from 'common/@formily/components/Switch'
import CrudModalForm from 'common/components/CrudModalForm'
import { CrudModalFormProps } from 'common/components/CrudModalForm/interface'
import { useUserConfig } from 'common/context/UserConfig'

export type TodoSettingEntity = Pick<
  UserConfigEntity,
  'refresh' | 'refreshInterval'
>

const items: CrudModalFormProps<TodoSettingEntity>['items'] = [
  {
    name: 'refresh',
    title: '自动刷新',
    type: 'boolean',
    'x-decorator': FormItem,
    'x-component': Switch
  },
  {
    name: 'refreshInterval',
    title: '刷新间隔',
    type: 'number',
    minimum: 1,
    maximum: 10,
    'x-decorator': FormItem,
    'x-component': InputNumber,
    'x-component-props': {
      suffix: '分钟'
    }
  }
]

const TodoSetting = () => {
  const config = useUserConfig()

  const initialValues = useMemo(
    () => ({
      id: config.id,
      refresh: config.refresh,
      refreshInterval: config.refreshInterval
    }),
    [config]
  )
  const onOk = (value: UserConfigEntity) => {
    config.dispatch?.({
      refresh: value.refresh,
      refreshInterval: value.refreshInterval
    })
  }

  return (
    <CrudModalForm
      initialValues={initialValues}
      items={items}
      name='todoSetting'
      title='待办设置'
      onOk={onOk}
    >
      <Button key='setting' disabled={!config.id} type='link'>
        设 置
      </Button>
    </CrudModalForm>
  )
}

export default TodoSetting
