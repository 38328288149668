import { Card, Collapse } from 'antd'

import { formGridConfig } from 'common/config/formGrid'

import { FormGrid } from '../FormGrid'
import FormLayout from '../FormLayout'
import useFormLayout from '../FormLayout/hooks/useFormLayout'

import { FormBlockProps } from './interface'

const FormBlock = (props: FormBlockProps) => {
  const { title, children, grid, layout = {}, collapse } = props

  const { inset, labelWidth } = useFormLayout()

  const {
    inset: customizeInset,
    labelWidth: customizeLabelWidth,
    ...restLayoutProps
  } = layout

  const finalLabelWidth = customizeLabelWidth ?? labelWidth

  const content = (
    <FormLayout
      inset={inset ?? customizeInset}
      labelWidth={inset ? undefined : finalLabelWidth}
      {...restLayoutProps}
    >
      {grid ? (
        <FormGrid {...formGridConfig} {...grid} children={children} />
      ) : (
        children
      )}
    </FormLayout>
  )

  if (collapse) {
    return (
      <Collapse
        bordered={false}
        style={{
          backgroundColor: 'transparent'
        }}
      >
        <Collapse.Panel key='default' header={title}>
          {content}
        </Collapse.Panel>
      </Collapse>
    )
  }

  return (
    <Card
      bodyStyle={{ paddingLeft: 0, paddingRight: 0 }}
      bordered={false}
      headStyle={{ padding: 0 }}
      size='small'
      title={title}
    >
      {content}
    </Card>
  )
}

export default FormBlock
