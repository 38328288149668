import classNames from 'classnames'
import { isNil } from 'lodash-es'

import { BaseProps } from 'common/interface/component'

import { usePrefixCls } from '../hooks/clazz'
import { usePlaceholder } from '../hooks/placeholder'

type PreviewTextProps = BaseProps & {
  value?: string | number | null
  dangerouslySetInnerHTML?: boolean
}

const PreviewText = (props: PreviewTextProps) => {
  const { className, style, value, dangerouslySetInnerHTML } = props

  const prefixCls = usePrefixCls('preview-text')

  const text = usePlaceholder(value)

  return (
    <div
      className={classNames(prefixCls, className)}
      dangerouslySetInnerHTML={
        dangerouslySetInnerHTML
          ? {
              __html: text
            }
          : undefined
      }
      style={style}
      title={isNil(value) ? undefined : text}
    >
      {text}
    </div>
  )
}

export default PreviewText
