import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { parse } from 'query-string'

const useUrlParams = () => {
  // 获取当前的路由信息
  const { search } = useLocation()
  // 获取路由信息中的参数
  const params = useMemo(() => parse(search), [search])

  return params as Record<string, any>
}

export default useUrlParams
