/**
 * @desc 保存
 */
import { CustomerCollectEntity, CustomerCollectVo } from 'api/hsu/interface'
import fetcher from 'common/lib/fetcher'
import { MyAxiosRequestConfig } from 'common/interface/crud'

export type CustomerCollectInsertDataParams = CustomerCollectVo

export type CustomerCollectInsertDataResponse = CustomerCollectEntity

export const customerCollectInsertDataMethod = 'POST'

export const customerCollectInsertDataUrl = '/hsu/v1/customer/collect'

export function customerCollectInsertDataRequest(
  data?: CustomerCollectInsertDataParams,
  options?: MyAxiosRequestConfig
) {
  return fetcher.request<CustomerCollectInsertDataResponse>({
    url: customerCollectInsertDataUrl,
    method: customerCollectInsertDataMethod,
    data,
    ...options
  })
}

customerCollectInsertDataRequest.method = customerCollectInsertDataMethod
customerCollectInsertDataRequest.url = customerCollectInsertDataUrl
