/**
 * @desc 根据id查询
 */
import { ContractEntity } from 'api/hsu/interface'
import fetcher from 'common/lib/fetcher'
import { MyAxiosRequestConfig } from 'common/interface/crud'

export type SaleContractGetByIdParams = Partial<{
  /** id */
  id: string
}>

export type SaleContractGetByIdResponse = ContractEntity

export const saleContractGetByIdMethod = 'GET'

export const saleContractGetByIdUrl = '/hsu/v1/saleContract/byId/{id}'

export function saleContractGetByIdRequest(
  params?: SaleContractGetByIdParams,
  options?: MyAxiosRequestConfig
) {
  return fetcher.request<SaleContractGetByIdResponse>({
    url: saleContractGetByIdUrl,
    method: saleContractGetByIdMethod,
    params,
    ...options
  })
}

saleContractGetByIdRequest.method = saleContractGetByIdMethod
saleContractGetByIdRequest.url = saleContractGetByIdUrl
