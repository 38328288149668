/**
 * 页面上的操作
 */

import { OptionItem } from 'common/router/interface'

export const SALE_CONTRACT_AUDIT_RECEIPT_BTN = 'saleContractAuditReceiptBtn'

export const SALE_CONTRACT_AUDIT_RECEIPT_OPTION: OptionItem = {
  key: SALE_CONTRACT_AUDIT_RECEIPT_BTN,
  name: '核销应收款'
}
