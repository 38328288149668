import BasicLayout from 'common/layouts/BasicLayout'
import BlankLayout from 'common/layouts/BlankLayout'
import PageLayout from 'common/layouts/PageLayout'
import UserLayout from 'common/layouts/UserLayout'
import usePageRoute from 'common/router/hooks/usePageRouter'

import { LayoutSwitchProps } from './interface'

const LayoutSwitch = (props: LayoutSwitchProps) => {
  const { children } = props

  const { route } = usePageRoute()

  switch (route?.layout) {
    case 'BasicLayout':
      return <BasicLayout children={children} />
    case 'PageLayout':
      return <PageLayout children={children} />
    case 'UserLayout':
      return <UserLayout children={children} />
    case 'BlankLayout':
      return <BlankLayout children={children} />
    default:
      return <BasicLayout children={children} />
  }
}

export default LayoutSwitch
