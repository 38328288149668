import { Schema } from '@formily/json-schema'

import { CrudFormField } from '../interface'

export const createRandomNameCreator = () => {
  let nonameId = 0
  return () => `NO_NAME_FIELD_$${nonameId++}`
}

export const isVisibleField = (item: CrudFormField, scope: any) => {
  if (Schema.compile(item['x-visible'], scope) === false) {
    return false
  }

  if (Schema.compile(item['x-hidden'], scope) === true) {
    return false
  }

  const display = Schema.compile(item['x-display'], scope)

  return display !== 'none' && display !== 'hidden'
}
