/**
 * 页面上的操作按钮
 */

import { OptionItem } from 'common/router/interface'

export const COMPARE_AUDIT_IDENTIFY = 'compareAuditIdentify'

export const COMPARE_AUDIT_EDIT_IDENTIFY = 'compareAuditEditIdentify'

// 列表审核按钮
export const COMPARE_AUDIT_BTN: OptionItem = {
  key: COMPARE_AUDIT_IDENTIFY,
  name: '审核'
}
// 编辑页审核按钮
export const COMPARE_AUDIT_EDIT_BTN: OptionItem = {
  key: COMPARE_AUDIT_EDIT_IDENTIFY,
  name: '审核'
}
