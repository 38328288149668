import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { parseUrl } from 'query-string'

import { arrayTree2Map } from 'common/lib/util'
import { RouteItem } from 'common/router/interface'

const useMatches = (menus: RouteItem[]) => {
  const { pathname } = useLocation()

  const menuMap = useMemo(() => arrayTree2Map(menus, 'key'), [menus])
  const menuArr = useMemo(() => Object.values(menuMap), [menuMap])

  return useMemo(() => {
    const item = menuArr.find(
      ({ path }) => path && parseUrl(path).url === pathname
    )

    if (item) {
      const keys = item.parentKeys ? [...item.parentKeys, item.key] : [item.key]
      return keys.map(key => menuMap[key])
    }

    return []
  }, [menuArr, menuMap, pathname])
}

export default useMatches
