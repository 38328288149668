/**
 * @desc 根据id查询
 */
import { CargoAdjustEntity } from 'api/hsu/interface'
import fetcher from 'common/lib/fetcher'
import { MyAxiosRequestConfig } from 'common/interface/crud'

export type CargoAdjustGetByIdParams = Partial<{
  /** id */
  id: string
}>

export type CargoAdjustGetByIdResponse = CargoAdjustEntity

export const cargoAdjustGetByIdMethod = 'GET'

export const cargoAdjustGetByIdUrl = '/hsu/v1/cargoAdjust/byId/{id}'

export function cargoAdjustGetByIdRequest(
  params?: CargoAdjustGetByIdParams,
  options?: MyAxiosRequestConfig
) {
  return fetcher.request<CargoAdjustGetByIdResponse>({
    url: cargoAdjustGetByIdUrl,
    method: cargoAdjustGetByIdMethod,
    params,
    ...options
  })
}

cargoAdjustGetByIdRequest.method = cargoAdjustGetByIdMethod
cargoAdjustGetByIdRequest.url = cargoAdjustGetByIdUrl
