import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { CloseOutlined } from '@ant-design/icons'
import { Button, Card, Empty } from 'antd'

import { useUserConfig } from 'common/context/UserConfig'
import useBoolean from 'common/hooks/useBoolean'
import { arrayTree2Map, remove } from 'common/lib/util'
import useGlobalRouter from 'common/router/hooks/useGlobalRouter'
import { RouteItem } from 'common/router/interface'

import MenuPicker from './MenuPicker'

import './index.less'

const CustomEntry = () => {
  const history = useHistory()
  const [edit, editActions] = useBoolean(false)
  const { routes } = useGlobalRouter()
  const { id, menuSet, dispatch } = useUserConfig()
  const [checkedKeys, setCheckedKeys] = useState<string[]>([])
  const routeMap = useMemo(() => arrayTree2Map(routes, 'key'), [routes])
  const entries = useMemo(
    () => checkedKeys.map(key => routeMap[key]).filter(item => item),
    [routeMap, checkedKeys]
  )

  useEffect(() => {
    setCheckedKeys(menuSet || [])
  }, [menuSet])

  const finish = () => {
    editActions.toggle()
    // 完成操作
    if (edit) {
      dispatch?.({
        menuSet: checkedKeys
      })
    }
  }

  const removeEntry = (item: RouteItem) => {
    const newKeys = checkedKeys.slice()
    remove(newKeys, item.key)
    setCheckedKeys(newKeys)
    return newKeys
  }

  const onPickerChange = (value: string[]) => {
    setCheckedKeys(value)
  }

  const navigateTo = (item: RouteItem) => {
    if (item.path) {
      history.push(item.path)
    }
  }

  return (
    <Card
      bordered
      className='custom-entry'
      extra={[
        <Button key='edit' disabled={!id} type='link' onClick={finish}>
          {edit ? '完成' : '编辑'}
        </Button>
      ]}
      title='快捷入口'
    >
      {edit ? (
        <>
          {entries.map(item => (
            <Button
              key={item.key}
              className='custom-entry__item'
              type='dashed'
              onClick={() => removeEntry(item)}
            >
              {item.name}
              <CloseOutlined />
            </Button>
          ))}
          <MenuPicker value={checkedKeys} onChange={onPickerChange} />
        </>
      ) : entries.length ? (
        entries.map(item => (
          <Button
            key={item.key}
            className='custom-entry__item'
            type='dashed'
            onClick={() => navigateTo(item)}
          >
            {item.name}
          </Button>
        ))
      ) : (
        <Empty />
      )}
    </Card>
  )
}

export default CustomEntry
