/**
 * @desc 无需检查旧密码修改密码
 */
import { UserResponse, PasswordRequest } from 'api/auth/interface'
import fetcher from 'common/lib/fetcher'
import { MyAxiosRequestConfig } from 'common/interface/crud'

export type UserEditPasswordParams = PasswordRequest

export type UserEditPasswordResponse = UserResponse

export const userEditPasswordMethod = 'PUT'

export const userEditPasswordUrl = '/auth/v1/user/notCheck/password'

export function userEditPasswordRequest(
  data?: UserEditPasswordParams,
  options?: MyAxiosRequestConfig
) {
  return fetcher.request<UserEditPasswordResponse>({
    url: userEditPasswordUrl,
    method: userEditPasswordMethod,
    data,
    ...options
  })
}

userEditPasswordRequest.method = userEditPasswordMethod
userEditPasswordRequest.url = userEditPasswordUrl
