import { message, Modal } from 'antd'
import Axios, { AxiosError } from 'axios'
import { forOwn, isNil, snakeCase } from 'lodash-es'
import { stringify } from 'query-string'

import { MyAxiosRequestConfig } from 'common/interface/crud'

import { TOKEN } from './localKey'
import store from './store'
import {
  isExternalUrl,
  isLoginPage,
  isNotEmpty,
  parseVariableUrl,
  redirect2Login,
  resolveApiPath,
  toArray
} from './util'

const getRealUrl = (url: string) => {
  if (isExternalUrl(url)) {
    return url
  }
  return resolveApiPath(url)
}

const instance = Axios.create({
  timeout: 60 * 1000,
  withCredentials: true,
  paramsSerializer: params => {
    return stringify(params)
  }
})

// 处理请求 url
instance.interceptors.request.use((config: MyAxiosRequestConfig) => {
  const { url, skipResolveUrl = false } = config

  if (!url) {
    throw new Error('请求地址不能为空')
  }

  if (!skipResolveUrl) {
    config.url = getRealUrl(url)
  }

  return config
})

// 处理 path variable
instance.interceptors.request.use(config => {
  const { params, data, url } = config
  const mixedParams = { ...params, ...data }

  config.url = parseVariableUrl(url as string, mixedParams)

  return config
})

// 处理请求参数
instance.interceptors.request.use(config => {
  const { params } = config

  if (params) {
    forOwn(params, (value, key) => {
      // 去除空值
      if (isNil(value)) {
        delete params[key]
        // 处理排序
      } else if (key === 'orders') {
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            forOwn(item, (v, k) => {
              params[`orders[${index}].${k}`] = snakeCase(v)
            })
          })
        }
        delete params[key]
        // 处理过滤
      } else if (key === 'filters') {
        forOwn(value, (v, k) => {
          if (isNotEmpty(v)) {
            params[`filters[${snakeCase(k)}]`] = toArray(v).toString()
          }
        })
        delete params[key]
      }
    })
  }

  return config
})

// 处理授权信息
instance.interceptors.request.use(config => {
  let headers: any = config.headers
  // token 信息
  const token = store.get(TOKEN)

  if (token) {
    if (!headers) {
      config.headers = headers = {}
    }

    if (
      !headers.Authorization ||
      headers.Authorization.toLowerCase().indexOf('basic') < 0
    ) {
      headers.Authorization = `Bearer ${token}`
    }
  }

  return config
})

// 是否已经重定向到 login 页面
let redirected = false

/** 处理响应 */
instance.interceptors.response.use(
  res => {
    const { data } = res
    if (data.code === 500) {
      return Promise.reject(data)
    }
    return Promise.resolve(data)
  },
  (error: AxiosError) => {
    // 手动取消了请求
    if ((error as any).__CANCEL__) {
      return Promise.reject(error)
    }

    if (error.code === 'ECONNABORTED') {
      return Promise.reject(new Error('网络请求超时'))
    }

    const { config, response } = error

    // 代码层面的错误
    if (!config) {
      return Promise.reject(error)
    }

    const { silent } = config as MyAxiosRequestConfig

    const tip = (msg: string) => {
      !silent && message.error(msg)
    }

    if (!response) {
      tip('网络异常，请刷新页面')
      return Promise.reject(error)
    }

    const { data, status } = response

    // 请求失败
    if (status !== 401) {
      tip(
        data.error_description ||
          data.message ||
          `请求路径：${config.url}，错误码：${status}`
      )
      return Promise.reject(data)
    }

    // 登录错误
    if (isLoginPage()) {
      tip(data.error_description || data.error)
      return Promise.reject(data)
    }

    if (redirected) {
      return Promise.reject(data)
    }

    // 后续不需要设置回 false，因为跳转时刷新页面了
    redirected = true

    if (store.get(TOKEN)) {
      // 登录已失效
      Modal.warning({
        content: '登录已失效，请重新登录',
        title: '提示',
        onOk: redirect2Login
      })
    } else {
      redirect2Login()
    }

    return Promise.reject(data)
  }
)

/**
 * 因为在 response 拦截器中返回的不是 AxiosResponse 对象，所以需要重写请求方法，覆盖默认的 ts 签名
 */
const fetcher = {
  delete<R>(url: string, config?: MyAxiosRequestConfig): Promise<R> {
    return instance.delete(url, config)
  },
  get<R>(url: string, config?: MyAxiosRequestConfig): Promise<R> {
    return instance.get(url, config)
  },
  post<R>(
    url: string,
    data?: unknown,
    config?: MyAxiosRequestConfig
  ): Promise<R> {
    return instance.post(url, data, config)
  },
  put<R>(
    url: string,
    data?: unknown,
    config?: MyAxiosRequestConfig
  ): Promise<R> {
    return instance.put(url, data, config)
  },
  request<R>(config: MyAxiosRequestConfig): Promise<R> {
    return instance.request(config)
  }
}

export default fetcher
