import { Redirect } from 'react-router-dom'

import { useCurrentUser } from 'common/context/CurrentUser'
import useGlobalRouter from 'common/router/hooks/useGlobalRouter'
import { AuthorizationControllerProps } from 'common/router/interface'
import { isAuthorized } from 'common/router/util'

const AuthorizationController = (props: AuthorizationControllerProps) => {
  const { route, children } = props

  const { loaded, isAdmin } = useCurrentUser()
  const { wholePermissions } = useGlobalRouter()

  const authorized = !loaded || isAuthorized(route, wholePermissions, isAdmin)

  return authorized ? <>{children}</> : <Redirect exact to='/exception/403' />
}

export default AuthorizationController
