/**
 * @desc 根据id查询
 */
import { BillVo } from 'api/hsu/interface'
import fetcher from 'common/lib/fetcher'
import { MyAxiosRequestConfig } from 'common/interface/crud'

export type BillGetByIdParams = Partial<{
  /** id */
  id: string
}>

export type BillGetByIdResponse = BillVo

export const billGetByIdMethod = 'GET'

export const billGetByIdUrl = '/hsu/v1/gebill/{id}'

export function billGetByIdRequest(
  params?: BillGetByIdParams,
  options?: MyAxiosRequestConfig
) {
  return fetcher.request<BillGetByIdResponse>({
    url: billGetByIdUrl,
    method: billGetByIdMethod,
    params,
    ...options
  })
}

billGetByIdRequest.method = billGetByIdMethod
billGetByIdRequest.url = billGetByIdUrl
