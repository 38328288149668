import classNames from 'classnames'

import { usePrefixCls } from 'common/@formily/hooks/clazz'
import { useTransformSize } from 'common/hooks/useSize'

import { FormLayoutDeepContext, FormLayoutShallowContext } from './context'
import useFormDeepLayout from './hooks/useFormDeepLayout'
import { useResponsiveFormLayout } from './hooks/useResponsiveFormLayout'
import { FormLayoutProps } from './interface'

const FormLayout = ({
  size,
  shallow = true,
  children,
  prefixCls,
  className,
  style,
  ...otherProps
}: FormLayoutProps) => {
  const finalSize = useTransformSize(size)

  const { ref, props } = useResponsiveFormLayout({
    ...otherProps,
    size: finalSize
  })
  const deepLayout = useFormDeepLayout()
  const formPrefixCls = usePrefixCls('form', { prefixCls })
  const layoutPrefixCls = usePrefixCls('formily-layout', { prefixCls })
  const layoutClassName = classNames(
    layoutPrefixCls,
    {
      [`${formPrefixCls}-${props.layout}`]: true,
      [`${formPrefixCls}-rtl`]: props.direction === 'rtl',
      [`${formPrefixCls}-${props.size}`]: props.size
    },
    className
  )
  const renderChildren = () => {
    const newDeepLayout = {
      ...deepLayout
    }
    if (!shallow) {
      Object.assign(newDeepLayout, props)
    } else {
      if (props.size) {
        newDeepLayout.size = props.size
      }
      if (props.colon) {
        newDeepLayout.colon = props.colon
      }
    }
    return (
      <FormLayoutDeepContext.Provider value={newDeepLayout}>
        <FormLayoutShallowContext.Provider value={shallow ? props : {}}>
          {children}
        </FormLayoutShallowContext.Provider>
      </FormLayoutDeepContext.Provider>
    )
  }
  return (
    <div ref={ref} className={layoutClassName} style={style}>
      {renderChildren()}
    </div>
  )
}

export default FormLayout
