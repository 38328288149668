import { createContext, useContext } from 'react'

import { RouteItem } from 'common/router/interface'

type LayoutContextState = {
  menus: RouteItem[]
  matches: RouteItem[]
}

export const LayoutContext = createContext<LayoutContextState>({
  menus: [],
  matches: []
})

export const useLayout = () => useContext(LayoutContext)
