import { UserOutlined } from '@ant-design/icons'

import { RouteItem } from 'common/router/interface'

const user: RouteItem = {
  key: 'my',
  name: '用户中心',
  icon: <UserOutlined />,
  path: '/user',
  hideInMenu: true,
  authorize: false,
  component: () => import('hsu/pages/User/index')
}

export default user
